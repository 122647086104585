import { call, put } from 'redux-saga/effects';
import axios from './axiosHandler';
import { API_HOST } from '../env';
import sagaLoading from './sagaLoading';
import {
  MESSAGE_ERROR,
  GET_ALL_MEMBERS_SUCCESS,
  GET_ALL_GROUPS_SEGMENTS_SUCCESS,
  GET_ALL_GROUPS_SEGMENTS_RESPONSE,
} from '../actions/actionTypes';

export default function* sagaGetAllGroupSegments(data) {
  // console.log('[sagaGetAllGroupSegments data]', data);
  // const { limit, page, email, name, membershipId } = data.data;
  // try {
  //   yield call(sagaLoading, true);
  //   let url = `${API_HOST}notification/list?limit=${limit || 10}&pageNumber=${page || 1}`;
  //   if (email) {
  //     url += `&email=${email}`;
  //   }
  //   if (name) {
  //     url += `&name=${name}`;
  //   }
  //   if (membershipId) {
  //     url += `&membershipId=${membershipId}`;
  //   }
  //   const obj = {
  //     url,
  //     method: 'get',
  //   };
  //   const resData = yield call(() =>
  //     // axios({
  //     //   url: `${API_HOST}user/getAllUsers`,
  //     //   method: 'post',
  //     //   ...data,
  //     // }).then(response => response.data));
  //     axios(obj).then((response) => response.data)
  //   );
  //   yield put({ type: GET_ALL_GROUPS_SEGMENTS_SUCCESS, payload: resData.data });
  //   yield call(sagaLoading, false);
  // } catch (error) {
  //   // eslint-disable-next-line no-console
  //   console.log(error);
  //   yield call(sagaLoading, false);
  //   yield put({
  //     type: MESSAGE_ERROR,
  //     payload: error.message ? error.message : 'There is something wrong, please, try again.',
  //   });
  // }
  // const { limit, page } = data.data;
  try {
    yield call(sagaLoading, true);
    const resData = yield call(() =>
      axios({
        url: `${API_HOST}notification/listSegments`,
        method: 'get',
      }).then((response) => response.data)
    );
    if (resData && resData.data) {
      let finalArr = resData.data.map((str, index) => ({ name: str, id: str }))
      yield put({ type: GET_ALL_GROUPS_SEGMENTS_RESPONSE, payload: finalArr });
    }
    yield call(sagaLoading, false);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
    yield call(sagaLoading, false);
    yield put({
      type: MESSAGE_ERROR,
      payload: error.message ? error.message : 'There is something wrong, please, try again.',
    });
  }
}
