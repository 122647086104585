import { call, put, take, fork } from 'redux-saga/effects';
import { eventChannel, END } from 'redux-saga';
import axios from './axiosHandler';
import { API_HOST } from '../env';
import sagaLoading from './sagaLoading';
import sagaGetAllNotifications from './sagaGetAllNotifications';
import { MESSAGE_ERROR, MESSAGE_SUCCESS } from '../actions/actionTypes';

function upload(payload, onProgress) {
  const url = `${API_HOST}notification/create`;
  const singleData = new FormData();
  Object.keys(payload).map((item) => {
    if (item === 'notificationImage') {
      singleData.append(item, payload[item]);
    } else {
      singleData.set(item, payload[item]);
    }
    return null;
  });
  const config = {
    onUploadProgress: onProgress,
  };

  // return axios.post(url, singleData, config);
  return axios({
    url,
    method: 'post',
    data: singleData,
    config,
  });
}

function createUploader(payload) {
  let emit;
  const chan = eventChannel((emitter) => {
    emit = emitter;
    return () => {};
  });

  const uploadPromise = upload(payload, (event) => {
    if (event.loaded === event.total) {
      emit(0);
      emit(END);
    }
    emit(Math.round((event.loaded * 100) / event.total));
  });

  return [uploadPromise, chan];
}

function* watchOnProgress(chan) {
  while (true) {
    const data = yield take(chan);
    yield put({ type: 'UPLOAD_PROGRESS', payload: data });
  }
}

export default function* sageUpdateNotification({ data }) {
  const [uploadPromise, chan] = createUploader(data);
  yield fork(watchOnProgress, chan);
  try {
    console.log('[MEMBER_NOTIFICATION saga data]', data);
    yield call(sagaLoading, true);
    yield call(() => uploadPromise);
    yield call(sagaGetAllNotifications);
    yield put({ type: MESSAGE_SUCCESS, payload: 'Updated successfully' });
    yield call(sagaLoading, false);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
    yield call(sagaLoading, false);
    yield put({
      type: MESSAGE_ERROR,
      payload: error.message ? error.message : 'There is something wrong, please, try again.',
    });
  }
}
