import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Pagination from '@material-ui/lab/Pagination';

import CouponForm from './CouponForm';

import icoEdit from '../../assets/images/icons/ico-edit.svg';
import icoAdd from '../../assets/images/icons/ico-add.svg';
import icoTrash from '../../assets/images/icons/ico-trash.svg';

import {
  COUPON_ADD_REQUEST,
  COUPON_UPDATE_REQUEST,
  GET_ALL_MEMBERSHIPS_REQUEST,
  COUPON_DELETE_REQUEST,
  GET_ALL_COUPONS_REQUEST,
} from '../../redux/actions/actionTypes';

function Coupons(props) {
  const {
    addCoupon,
    updateCoupon,
    deleteCoupon,
    getAllCoupons,
    allCoupons,
    allMemberships,
    getAllMemberships,
  } = props;
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [couponData, setCouponData] = useState();

  // Pagination
  const [activePage, setActivePage] = useState(1);
  const [pagesCount, setPagesCount] = useState(1);
  const [itemsPerPage] = useState(10);

  useEffect(() => {
    if (allCoupons) {
      setPagesCount(Math.ceil(allCoupons.length / itemsPerPage));
    }
  }, [allCoupons, itemsPerPage]);
  useEffect(() => {
    if (activePage > pagesCount) {
      setActivePage(1);
    }
  }, [pagesCount, activePage]);

  const handlePageChange = (event, value) => {
    setActivePage(value);
  };

  // Get Coupons List
  useEffect(() => {
    getAllCoupons();
  }, [getAllCoupons]);

  // Get all memberships
  useEffect(() => {
    if (allMemberships.length === 0) {
      getAllMemberships();
    }
  }, [getAllMemberships, allMemberships.length]);

  // Add/Update Coupon
  const openDialog = (data) => {
    if (data.code) {
      setCouponData(data);
    }
    setIsDialogOpen(true);
  };

  const closeDialog = () => {
    setIsDialogOpen(false);
    setCouponData(undefined);
  };

  const submit = (values) => {
    if (couponData) {
      const updatingData = couponData;
      // eslint-disable-next-line
      updatingData.id = couponData['_id'];
      // eslint-disable-next-line no-return-assign
      Object.keys(values).map((item) => (updatingData[item] = values[item]));
      updateCoupon(updatingData);
    } else {
      addCoupon(values);
    }
    closeDialog();
  };

  // Delete Coupon
  const openDeleteDialog = (data) => {
    setCouponData(data);
    setIsDeleteDialogOpen(true);
  };

  const closeDeleteDialog = () => {
    setIsDeleteDialogOpen(false);
    setCouponData(undefined);
  };

  const confirmDelete = () => {
    // eslint-disable-next-line
    deleteCoupon({ code: couponData['code'] });
    closeDeleteDialog();
  };

  return (
    <div className="coupons memberships">
      <div className="page-title">
        <h1>Coupons</h1>
      </div>
      <Paper className="paper">
        <Table stickyHeader className="table">
          <TableHead className="table-head">
            <TableRow>
              <TableCell className="th" />
              <TableCell className="th">
                <span>Code</span>
              </TableCell>
              <TableCell className="th">
                <span>Usage Limit</span>
              </TableCell>
              <TableCell className="th">
                <span>Membership</span>
              </TableCell>
              <TableCell className="th">
                <span>Used Times</span>
              </TableCell>
              <TableCell className="th">
                <span className="text-center">Actions</span>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody className="table-body">
            {allCoupons
              .slice((activePage - 1) * itemsPerPage, activePage * itemsPerPage)
              .map((row, index) => (
                <TableRow key={row.name}>
                  <TableCell className="cell" style={{ textAlign: 'center' }}>
                    <span># {index + 1}</span>
                  </TableCell>
                  <TableCell className="cell">
                    <span>{row.code}</span>
                  </TableCell>
                  <TableCell className="cell">
                    <span>{row.useLimit}</span>
                  </TableCell>
                  <TableCell className="cell">
                    <span>{row.membership.name}</span>
                  </TableCell>
                  <TableCell className="cell">
                    <span>{row.numberOfUse}</span>
                  </TableCell>
                  <TableCell className="cell">
                    <div className="action-icons">
                      <div
                        className="action-icon"
                        role="presentation"
                        onClick={() => openDialog(row)}
                      >
                        <img src={icoEdit} alt="" className="edit-ico" />
                      </div>
                      <div
                        className="action-icon"
                        role="presentation"
                        onClick={() => openDeleteDialog(row)}
                      >
                        <img src={icoTrash} alt="" className="edit-ico" />
                      </div>
                    </div>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>

        <div className="add-ico" role="presentation" onClick={openDialog}>
          <img src={icoAdd} alt="" />
        </div>
      </Paper>

      {pagesCount !== 1 ? (
        <div className="pagination-wrapper">
          <Pagination count={pagesCount} page={activePage} onChange={handlePageChange} />
        </div>
      ) : null}

      <Dialog open={isDialogOpen} onClose={closeDialog} className="coupons-dialog">
        <DialogTitle>{couponData ? 'Update Coupon' : 'Add Coupon'}</DialogTitle>
        <DialogContent>
          <CouponForm
            initialValues={couponData}
            closeDialog={closeDialog}
            onSubmit={submit}
            allMemberships={allMemberships}
          />
        </DialogContent>
      </Dialog>

      <Dialog open={isDeleteDialogOpen} onClose={closeDeleteDialog} className="coupons-dialog">
        <DialogTitle>Delete Coupon</DialogTitle>
        <DialogContent>
          <p>Are you sure you want to delete this Coupon?</p>
          <div className="text-center customForm">
            <button
              type="button"
              onClick={closeDeleteDialog}
              className="submit-button cancel-button"
            >
              No
            </button>
            <button type="button" className="submit-button" onClick={confirmDelete}>
              Yes
            </button>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}

Coupons.propTypes = {
  addCoupon: PropTypes.func,
  getAllMemberships: PropTypes.func,
  allMemberships: PropTypes.arrayOf(PropTypes.shape({})),
  updateCoupon: PropTypes.func,
  deleteCoupon: PropTypes.func,
  getAllCoupons: PropTypes.func,
  allCoupons: PropTypes.arrayOf(PropTypes.shape({})),
};

Coupons.defaultProps = {
  addCoupon: undefined,
  allMemberships: undefined,
  updateCoupon: undefined,
  deleteCoupon: undefined,
  getAllMemberships: undefined,
  getAllCoupons: undefined,
  allCoupons: undefined,
};

const mapStateToProps = (state) => ({
  loading: state.loading,
  allCoupons: state.allCoupons,
  allMemberships: state.allMemberships,
});

const actionsStateToProps = {
  getAllMemberships: () => ({ type: GET_ALL_MEMBERSHIPS_REQUEST }),
  addCoupon: (data) => ({ type: COUPON_ADD_REQUEST, data }),
  updateCoupon: (data) => ({ type: COUPON_UPDATE_REQUEST, data }),
  deleteCoupon: (data) => ({ type: COUPON_DELETE_REQUEST, data }),
  getAllCoupons: () => ({ type: GET_ALL_COUPONS_REQUEST }),
};

export default connect(mapStateToProps, actionsStateToProps)(Coupons);
