import React, { useEffect, useState } from 'react';
import { Field, reduxForm, Form } from 'redux-form';
import PropTypes from 'prop-types';

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import FileField from '../common/FileField';
import TextField from '../common/TextField';
import TagsField from '../common/TagsField';
import TextareaField from '../common/TextareaField';
import MultiselectField from '../common/MultiselectField';
import SingleUploadForm from './SingleUploadForm';
import { isExist } from '../../utils/helpers';

const required = (value) => (value && value !== '' ? undefined : 'Required');
const fileSelect = (value) => (value ? undefined : 'You forgot to choose files');

function UploadForm(props) {
  const {
    handleSubmit,
    pristine,
    uploadType = 'series',
    submitting,
    uploadFormat,
    allMemberships,
    allMeditations,
    initialValues,
    uploadSingle,
    formName,
    namesArray,
    uploadedSingle,
  } = props;
  const [items, setItems] = useState([{ id: 1, disabled: false }]);
  const [isUploaded, setIsUploaded] = useState({});

  // Get Initial Data
  const membershipsArray = [];
  allMemberships.map((item) => {
    // eslint-disable-next-line
    membershipsArray.push({ name: item.name, id: item['_id'] });
    return null;
  });
  const notUnique = (value) =>
    isExist(namesArray, value) ? undefined : 'This name is already exist';

  // Block Single items after Upload
  useEffect(() => {
    if (JSON.stringify(uploadedSingle) !== JSON.stringify(isUploaded)) {
      setIsUploaded(uploadedSingle);
      const curArray = items.map((item) => ({ id: item.id, disabled: true }));
      setItems(curArray);
    }
  }, [uploadedSingle, items, isUploaded]);

  // Block Single items after Upload
  const addItem = () => {
    const curArray = items.map((item) => item);
    if (items.length !== 0) {
      curArray.push({ id: items[items.length - 1].id + 1, disabled: false });
    } else {
      curArray.push({ id: 1, disabled: false });
    }
    setItems(curArray);
  };

  // Upload Single item to Series
  const submitSingle = (values) => {
    uploadSingle(values);
  };

  // Delete Single item from page
  const deleteItem = (id) => {
    const curArray = items.filter((item) => item.id !== id);
    setItems(curArray);
  };

  return (
    <>
      <Form onSubmit={handleSubmit} form={formName} className="customForm multipleUpload">
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Field
              name="name"
              component={TextField}
              label={uploadType === 'series' ? 'Series Name' : 'Program Name'}
              placeholder="Enter name"
              validate={[required, notUnique]}
              disabled={!!initialValues.seriesId}
            />
          </Grid>
          <Grid item xs={6}>
            <Field
              name="memberships"
              component={MultiselectField}
              label={uploadType === 'series' ? 'Series Membership' : 'Program Membership'}
              placeholder="Select Membership"
              validate={required}
              disabled={!!initialValues.seriesId}
            >
              {membershipsArray.map((item) => item)}
            </Field>
          </Grid>
        </Grid>

        <div>
          <Field
            name="tags"
            component={TagsField}
            label={uploadType === 'series' ? 'Series Tags' : 'Program Tags'}
            fullWidth
            placeholder="Enter #tags"
            validate={required}
            disabled={!!initialValues.seriesId}
          />
        </div>

        <div>
          <Field
            name="thumbnail"
            component={FileField}
            label={uploadType === 'series' ? 'Series Thumbnail' : 'Program Thumbnail'}
            validate={fileSelect}
            disabled={!!initialValues.seriesId}
          />
        </div>

        <div>
          <Field
            name="animation"
            component={FileField}
            label={uploadType === 'series' ? 'Series Animation' : 'Program Animation'}
            validate={fileSelect}
            disabled={!!initialValues.seriesId}
          />
        </div>

        <div>
          <Field
            name="description"
            component={TextareaField}
            label={uploadType === 'series' ? 'Series Description' : 'Program Description'}
            placeholder="Enter description"
            multiline
            fullWidth
            rowsMax="4"
            maxLength="1200"
            validate={required}
            disabled={!!initialValues.seriesId}
          />
        </div>

        {!initialValues.seriesId ? (
          <div className="text-right">
            <button type="submit" className="submit-button" disabled={pristine || submitting}>
              Continue
            </button>
          </div>
        ) : null}
      </Form>

      {initialValues.seriesId ? (
        <>
          {items.map((item) => (
            <Paper className="paper inner-papper" key={item.id}>
              <div className="top-wrapper">
                <div className="h3">
                  <h3>
                    {uploadFormat} {item.id}
                  </h3>
                </div>
                {!item.disabled ? (
                  <div className="btn-wrapper">
                    <div
                      className="btn-delete"
                      role="presentation"
                      onClick={() => deleteItem(item.id)}
                    >
                      Delete
                    </div>
                  </div>
                ) : null}
              </div>
              <SingleUploadForm
                onSubmit={submitSingle}
                form={item.id.toString()}
                initialValues={{ ...initialValues, type: `single${uploadFormat}` }}
                uploadFormat={uploadFormat}
                allMemberships={allMemberships}
                allMeditations={allMeditations}
                namesArray={namesArray}
                disabled={item.disabled}
              />
            </Paper>
          ))}
          <div className="text-right">
            <button type="button" className="submit-button add-button" onClick={addItem}>
              {`Add more ${uploadFormat}`}
            </button>
          </div>
        </>
      ) : null}
    </>
  );
}

UploadForm.propTypes = {
  handleSubmit: PropTypes.func,
  pristine: PropTypes.bool,
  submitting: PropTypes.bool,
  allMemberships: PropTypes.arrayOf(PropTypes.shape({})),
  uploadType: PropTypes.string,
  initialValues: PropTypes.shape({
    seriesId: PropTypes.string,
  }),
  uploadFormat: PropTypes.string,
  uploadSingle: PropTypes.func,
  allMeditations: PropTypes.arrayOf(PropTypes.shape({})),
  formName: PropTypes.string,
  namesArray: PropTypes.arrayOf(PropTypes.string),
  uploadedSingle: PropTypes.shape({}),
};

UploadForm.defaultProps = {
  handleSubmit: undefined,
  pristine: undefined,
  allMeditations: undefined,
  submitting: undefined,
  allMemberships: undefined,
  initialValues: undefined,
  uploadFormat: undefined,
  uploadType: 'series',
  uploadSingle: undefined,
  formName: undefined,
  namesArray: undefined,
  uploadedSingle: undefined,
};

export default reduxForm({
  form: 'form',
})(UploadForm);
