import React from 'react';
import PropTypes from 'prop-types';

import { Field, Form, reduxForm } from 'redux-form';
import { Grid, Paper } from '@material-ui/core';
import TextField from '../common/TextField';
import SelectField from '../common/SelectField';

function SearchMemberForm(props) {
  const { allMemberships, handleSubmit } = props;

  // Get Initial Data
  const membershipsArray = [];
  membershipsArray.push(
    // eslint-disable-next-line no-underscore-dangle
    <option key={null} value="">
      All
    </option>
  );
  allMemberships.map((item) => {
    membershipsArray.push(
      // eslint-disable-next-line no-underscore-dangle
      <option key={item._id} value={item._id}>
        {item.name}
      </option>
    );
    return null;
  });

  return (
    <Paper className="paper" style={{ marginBottom: 20 }}>
      <div className="item-wrapper-search">
        <Form className="customForm addMembership" onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <Field name="name" component={TextField} label="Name" placeholder="Enter name" />
            </Grid>
            <Grid item xs={3}>
              <Field name="email" component={TextField} label="Email" placeholder="Enter email" />
            </Grid>
            <Grid item xs={3}>
              <Field name="membershipId" component={SelectField} label="Membership" placeholder="">
                {membershipsArray.map((item) => item)}
              </Field>
            </Grid>
            <Grid item xs={3} className="button-container text-right">
              <button type="submit" className="submit-button">
                Search
              </button>
            </Grid>
          </Grid>
        </Form>
      </div>
    </Paper>
  );
}

SearchMemberForm.propTypes = {
  initialValues: PropTypes.shape({}),
  handleSubmit: PropTypes.func,
  allMemberships: PropTypes.arrayOf(PropTypes.shape({})),
};

SearchMemberForm.defaultProps = {
  initialValues: undefined,
  allMemberships: undefined,
  handleSubmit: undefined,
};

export default reduxForm({
  form: 'addMembership',
})(SearchMemberForm);
