import { GET_SINGLE_AUDIO_DETAILS_SUCCESS } from '../actions/actionTypes';

export default function audioDetails(state = {}, action) {
  switch (action.type) {
    case GET_SINGLE_AUDIO_DETAILS_SUCCESS:
      console.log('GET_SINGLE_AUDIO_DETAILS_SUCCESS ==> ', action.payload);
      return action.payload;
    default:
      return state;
  }
}
