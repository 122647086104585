import React from 'react';
import { Field, reduxForm, Form } from 'redux-form';
import PropTypes from 'prop-types';

import FileField from '../common/FileField';
import TextField from '../common/TextField';
import MultiselectField from '../common/MultiselectField';
import { isExist } from '../../utils/helpers';
import SelectField from '../common/SelectField';

const required = (value) => (value && value !== '' ? undefined : 'Required');
const fileSelect = (value) => (value ? undefined : 'You forgot to choose files');

function UploadForm(props) {
  const {
    handleSubmit,
    pristine,
    submitting,
    uploadFormat,
    allMemberships,
    allGroups,
    formName,
    namesArray,
    disabled,
  } = props;

  const membershipsArray = [];
  allMemberships.map((item) => {
    // eslint-disable-next-line
    membershipsArray.push({ name: item.name, id: item['_id'] });
    return null;
  });

  const groupsArray = [];
  allGroups.map((item) => {
    // eslint-disable-next-line
    groupsArray.push({ name: item.name, id: item['_id'] });
    return null;
  });

  const notUnique = (value) =>
    isExist(namesArray, value) ? undefined : 'This name is already exist';

  return (
    <Form onSubmit={handleSubmit} form={formName} className="customForm singleUpload">
      <div>
        <Field
          name="name"
          component={TextField}
          label="Name"
          placeholder="Enter your product name"
          validate={[required, notUnique]}
          disabled={disabled}
        />
      </div>

      <div>
        <Field
          name={uploadFormat === 'Audio' ? 'audio' : 'video'}
          component={FileField}
          label="File"
          validate={fileSelect}
          disabled={disabled}
        />
      </div>

      <div>
        <Field
          name="thumbnail"
          component={FileField}
          label="Thumbnail"
          validate={fileSelect}
          disabled={disabled}
        />
      </div>

      <div>
        <Field
          name="animation"
          component={FileField}
          label="Animation"
          validate={fileSelect}
          disabled={disabled}
        />
      </div>

      <div>
        <Field
          name="memberships"
          component={MultiselectField}
          label="Membership"
          placeholder="Select Membership"
          validate={required}
          disabled={disabled}
        >
          {membershipsArray.map((item) => item)}
        </Field>
      </div>

      <div>
        <Field name="isFeatured" component={SelectField} label="Is Featured">
          <option value="">Select</option>
          <option value>Yes</option>
          <option value={false}>No</option>
        </Field>
      </div>
      <div>
        <Field name="isPremium" component={SelectField} label="Is Premium?">
          <option value="">Select</option>
          <option value>Yes</option>
          <option value={false}>No</option>
        </Field>
      </div>

      <div>
        <Field
          name="groups"
          component={MultiselectField}
          label="Groups"
          placeholder="Select Groups"
          validate={required}
          disabled={disabled}
        >
          {groupsArray.map((item) => item)}
        </Field>
      </div>

      <div className="text-right">
        <button
          type="submit"
          className="submit-button"
          disabled={pristine || submitting || disabled}
        >
          Upload
        </button>
      </div>
    </Form>
  );
}

UploadForm.propTypes = {
  handleSubmit: PropTypes.func,
  pristine: PropTypes.bool,
  submitting: PropTypes.bool,
  allMemberships: PropTypes.arrayOf(PropTypes.shape({})),
  allGroups: PropTypes.arrayOf(PropTypes.shape({})),
  formName: PropTypes.string,
  uploadFormat: PropTypes.string,
  namesArray: PropTypes.arrayOf(PropTypes.string),
  disabled: PropTypes.bool,
  initialValues: PropTypes.shape({
    // programId: PropTypes.string,
  }),
};

UploadForm.defaultProps = {
  handleSubmit: undefined,
  pristine: undefined,
  submitting: undefined,
  allMemberships: undefined,
  allGroups: undefined,
  formName: undefined,
  uploadFormat: undefined,
  namesArray: undefined,
  disabled: undefined,
  initialValues: undefined,
};

export default reduxForm({
  form: 'form',
})(UploadForm);
