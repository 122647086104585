import React from 'react';
import PropTypes from 'prop-types';

import { Field, reduxForm, Form } from 'redux-form';
import TextField from '../common/TextField';
import FileField from '../common/FileField';
import SelectField from '../common/SelectField';

const required = (value) => (value && value !== '' ? undefined : 'Required');

function GroupForm(props) {
  const { handleSubmit, pristine, submitting, closeDialog, initialValues, allEntities } = props;

  // const [type, setType] = useState(null);

  // Get Initial Data
  const entitiesArray = [];
  allEntities.map((item) => {
    entitiesArray.push(
      // eslint-disable-next-line no-underscore-dangle
      <option key={item._id} value={item._id}>
        {item.firstName} {item.lastName}
      </option>
    );
    return null;
  });

  // Get Initial Data

  return (
    <Form onSubmit={handleSubmit} className="customForm addMembership coupons">
      <div>
        <Field
          name="name"
          component={TextField}
          label="Group Name"
          placeholder="Enter Group Name"
          validate={required}
        />
      </div>
      <div>
        <Field
          name="type"
          component={SelectField}
          label="Group Type"
          // onChange={e => setType(e.target.value)}
          placeholder=""
        >
          <option value="">Select Type</option>
          <option value="collection">Collection</option>
          <option value="course">Course</option>
          <option value="program">Program</option>
          <option value="masterclasses">Master Classes</option>
          <option value="genericCollection">Generic Collection</option>
        </Field>
      </div>
      <div>
        <Field name="entity" component={SelectField} label="Entity" placeholder="Select Entity">
          {entitiesArray.map((item) => item)}
        </Field>
      </div>
      <div>
        <Field name="isFeatured" component={SelectField} label="Is Featured">
          <option value="">Select</option>
          <option value>Yes</option>
          <option value={false}>No</option>
        </Field>
      </div>
      <div>
        <Field name="isPremium" component={SelectField} label="Is Premium?">
          <option value="">Select</option>
          <option value>Yes</option>
          <option value={false}>No</option>
        </Field>
      </div>
      <div>
        <Field
          name="image"
          label="Select Image"
          placeholder="Select Image"
          component={FileField}
          validate={undefined}
        />
      </div>
      <div className="text-right">
        <button type="button" onClick={closeDialog} className="submit-button cancel-button">
          Cancel
        </button>
        <button type="submit" className="submit-button" disabled={pristine || submitting}>
          {initialValues ? 'Update' : 'Add'}
        </button>
      </div>
    </Form>
  );
}

GroupForm.propTypes = {
  initialValues: PropTypes.shape({}),
  closeDialog: PropTypes.func,
  handleSubmit: PropTypes.func,
  pristine: PropTypes.bool,
  submitting: PropTypes.bool,
  allEntities: PropTypes.arrayOf(PropTypes.shape({})),
};

GroupForm.defaultProps = {
  initialValues: undefined,
  closeDialog: undefined,
  handleSubmit: undefined,
  pristine: undefined,
  submitting: undefined,
  allEntities: undefined,
};

export default reduxForm({
  form: 'addGroup',
})(GroupForm);
