import React from 'react';
import { Route, Switch } from 'react-router-dom';
import cookie from 'react-cookies';
import { NotificationContainer } from 'react-notifications';
import history from '../history';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
/* eslint-disable */
import Layout from './Layout';
import Login from '../components/Login/';
import RemindPassword from '../components/RemindPassword/';
import Registration from '../components/Registration/';
import ItemsList from '../components/ItemsList/';
import Upload from '../components/Upload/';
import Members from '../components/Members/';
import Memberships from '../components/Memberships/';
import Entities from '../components/Entities/';
import Groups from '../components/Groups';
import Coupons from '../components/Coupons/';
import Notifications from '../components/Notifications/';
import GroupNotifications from '../components/GroupNotification/';
import AudioDetails from '../components/AudioDetails/';
import Meditations from '../components/Meditations/';
import Tags from '../components/Tags/';
import ErrorPage from '../components/ErrorPage';
/* eslint-enable */

// added comment
import 'react-notifications/lib/notifications.css';
import Dashboard from '../components/Dashboard';

function App() {
  if (!cookie.load('token')) {
    if (
      history.location.pathname !== '/registration' &&
      history.location.pathname !== '/remind-password'
    ) {
      history.push('/login');
    }
  }

  return (
    <>
      <Switch>
        <Route path="/login" render={() => <Login />} />
        <Route path="/remind-password" render={() => <RemindPassword />} />
        <Route path="/registration" render={() => <Registration />} />
        <Route
          path="/videos/"
          render={() => <Layout component={<ItemsList key={1} listType="videos" />} />}
        />
        <Route path="/audios/:id/" render={() => <Layout component={<AudioDetails />} />} />
        <Route
          path="/audios/"
          render={() => <Layout component={<ItemsList key={2} listType="audios" />} />}
        />
        <Route path="/upload/" render={() => <Layout component={<Upload />} />} />
        <Route path="/members/" render={() => <Layout component={<Members />} />} />
        <Route path="/tags/" render={() => <Layout component={<Tags />} />} />
        <Route path="/memberships/" render={() => <Layout component={<Memberships />} />} />
        <Route path="/meditations/" render={() => <Layout component={<Meditations />} />} />
        <Route path="/entities/" render={() => <Layout component={<Entities />} />} />
        <Route path="/groups/" render={() => <Layout component={<Groups />} />} />
        <Route path="/coupons/" render={() => <Layout component={<Coupons />} />} />
        <Route path="/notifications/" render={() => <Layout component={<Notifications />} />} />
        <Route
          path="/groupNotifications/"
          render={() => <Layout component={<GroupNotifications />} />}
        />
        <Route exact path="/" render={() => <Layout component={<Dashboard />} />} />
        <Route component={ErrorPage} />
      </Switch>
      <NotificationContainer />
    </>
  );
}

export default App;
