import { GET_ALL_AUDIOS_SUCCESS } from '../actions/actionTypes';

export default function allAudios(state = [], action) {
  switch (action.type) {
    case GET_ALL_AUDIOS_SUCCESS:
      return action.payload;
    default:
      return state;
  }
}
