import { GET_ALL_NOTIFICATIONS_SUCCESS } from '../actions/actionTypes';

export default function allCoupons(state = [], action) {
  switch (action.type) {
    case GET_ALL_NOTIFICATIONS_SUCCESS:
      return action.payload;
    default:
      return state;
  }
}
