import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Pagination from '@material-ui/lab/Pagination';

import { IoIosNotifications } from 'react-icons/io';
import MeditationForm from './MeditationForm';

import icoEdit from '../../assets/images/icons/ico-edit.svg';
import icoAdd from '../../assets/images/icons/ico-add.svg';
import icoTrash from '../../assets/images/icons/ico-trash.svg';
import CustomNotificationForm from '../CustomNotification/CustomNotificationForm';

import {
  MEDITATION_ADD_REQUEST,
  MEDITATION_UPDATE_REQUEST,
  MEDITATION_DELETE_REQUEST,
  GET_ALL_MEDITATIONS_REQUEST,
  GET_ALL_MEMBERS_FOR_NOTIFICATION_REQUEST,
  CREATE_CUSTOM_NOTIFICATION,
} from '../../redux/actions/actionTypes';

function Meditations(props) {
  const {
    addMeditation,
    updateMeditation,
    deleteMeditation,
    getAllMeditations,
    allMeditations,
    getAllMembersForNotification,
    allMembersForNotification,
    createCustomNotification,
  } = props;
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [meditationData, setMeditationData] = useState();

  // Pagination
  const [activePage, setActivePage] = useState(1);
  const [pagesCount, setPagesCount] = useState(1);
  const [itemsPerPage] = useState(10);

  // Meditation Notification
  const [isDialogForMultipleUsersOpen, setIsDialogForMultipleUsersOpen] = useState(false);
  const [notificationData, setNotificationData] = useState();
  const [sendingMode, setSendingMode] = useState(2);

  useEffect(() => {
    if (allMeditations) {
      setPagesCount(Math.ceil(allMeditations.length / itemsPerPage));
    }
  }, [allMeditations, itemsPerPage]);
  useEffect(() => {
    if (activePage > pagesCount) {
      setActivePage(1);
    }
  }, [pagesCount, activePage]);

  const handlePageChange = (event, value) => {
    setActivePage(value);
  };

  // Get Meditations List
  useEffect(() => {
    getAllMeditations();
  }, [getAllMeditations]);

  // Add/Update Meditation
  const openDialog = (data) => {
    if (data.name) {
      setMeditationData(data);
    }
    setIsDialogOpen(true);
  };

  const closeDialog = () => {
    setIsDialogOpen(false);
    setMeditationData(undefined);
  };

  const submit = (values) => {
    if (meditationData) {
      const updatingData = meditationData;
      // eslint-disable-next-line
      updatingData.id = meditationData['_id'];
      // eslint-disable-next-line
      if (values.data) {
        // eslint-disable-next-line
        values.data = JSON.stringify(values.data);
      }
      // eslint-disable-next-line no-return-assign
      Object.keys(values).map((item) => (updatingData[item] = values[item]));
      updateMeditation(updatingData);
    } else {
      // eslint-disable-next-line
      if (values.data) {
        // eslint-disable-next-line
        values.data = JSON.stringify(values.data);
      }
      addMeditation(values);
    }
    closeDialog();
  };

  // Delete Meditation
  const openDeleteDialog = (data) => {
    setMeditationData(data);
    setIsDeleteDialogOpen(true);
  };

  const closeDeleteDialog = () => {
    setIsDeleteDialogOpen(false);
    setMeditationData(undefined);
  };

  const confirmDelete = () => {
    // eslint-disable-next-line
    deleteMeditation({ id: meditationData['_id'] });
    closeDeleteDialog();
  };

  useEffect(() => {
    const data = {
      name: null,
    };
    getAllMembersForNotification(data);
  }, [getAllMembersForNotification]);

  const openDialogForMultipleUsers = (data) => {
    console.log('[NOTIF_DEBUG allMembersForNotification]', allMembersForNotification);
    // if (data.title) {
    setNotificationData(data);
    // }
    setIsDialogForMultipleUsersOpen(true);
  };

  const closeDialogForMultipleUsers = () => {
    setIsDialogForMultipleUsersOpen(false);
    setNotificationData(undefined);
  };

  const submitNotificationForMultipleUsers = (values) => {
    console.log('[NOTIFICATION_IMAGE values]', values);
    let userIds = null;
    if (values.userIds && values.userIds.split(',').length > 0) {
      userIds = values.userIds.split(',');
    }
    const dataToSend = {
      // externalIds: userIds,
      // externalIds: ["5f38caa5c89bdc28ba532ffe"],
      title: values.title,
      body: values.body,
      notificationImage: values.notificationImage,
      notificationType: 'MEDITATION',
      notificationPayload: { _id: values._id },
    };
    if (sendingMode === 2) {
      dataToSend.externalIds = userIds;
    }
    console.log('[NOTIFICATION_IMAGE dataToSend]', dataToSend);
    createCustomNotification(dataToSend);
    closeDialogForMultipleUsers();
  };

  return (
    <div className="memberships meditation members">
      <div className="page-title">
        <h1>Meditations</h1>
      </div>
      <Paper className="paper">
        <Table stickyHeader className="table">
          <TableHead className="table-head">
            <TableRow>
              <TableCell className="th" />
              <TableCell className="th">
                <span>Name</span>
              </TableCell>
              <TableCell className="th">
                <span>Data</span>
              </TableCell>
              <TableCell className="th">
                <span>Image</span>
              </TableCell>
              <TableCell className="th">
                <span className="text-center">Actions</span>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody className="table-body">
            {allMeditations && allMeditations.length > 0
              ? allMeditations
                  .slice((activePage - 1) * itemsPerPage, activePage * itemsPerPage)
                  .map((row, index) => (
                    <TableRow key={row.name}>
                      <TableCell className="cell" style={{ textAlign: 'center' }}>
                        <span># {index + 1}</span>
                      </TableCell>
                      <TableCell className="cell">
                        <span>{row.name}</span>
                      </TableCell>
                      <TableCell className="cell">
                        <div>
                          {row.data && row.data.constructor === Array
                            ? row.data.map((data, idx) => (
                                <span>
                                  {idx + 1}. {data}
                                </span>
                              ))
                            : null}
                        </div>
                      </TableCell>
                      <TableCell className="cell">
                        <span>
                          <img
                            src={`https://retreat-content-test.s3.amazonaws.com/${row.fileLocation}`}
                            alt=""
                            className="icon"
                          />
                        </span>
                      </TableCell>
                      <TableCell className="cell">
                        <div className="head-cell cell-actions">
                          <div className="action-icons">
                            <div
                              className="action-icon"
                              role="presentation"
                              onClick={() => openDialog(row)}
                            >
                              <img src={icoEdit} alt="" className="edit-ico" />
                            </div>
                            <div
                              className="action-icon"
                              role="presentation"
                              onClick={() => openDeleteDialog(row)}
                            >
                              <img src={icoTrash} alt="" className="edit-ico" />
                            </div>
                            <div
                              className="action-icon"
                              role="presentation"
                              onClick={() => openDialogForMultipleUsers(row)}
                            >
                              <IoIosNotifications size={23} color="#00264e" />
                            </div>
                          </div>
                        </div>
                      </TableCell>
                    </TableRow>
                  ))
              : null}
          </TableBody>
        </Table>

        <div className="add-ico" role="presentation" onClick={openDialog}>
          <img src={icoAdd} alt="" />
        </div>
      </Paper>

      {pagesCount !== 1 ? (
        <div className="pagination-wrapper">
          <Pagination count={pagesCount} page={activePage} onChange={handlePageChange} />
        </div>
      ) : null}

      <Dialog open={isDialogOpen} onClose={closeDialog} className="meditations-dialog">
        <DialogTitle>{meditationData ? 'Update Meditation' : 'Add Meditation'}</DialogTitle>
        <DialogContent>
          <MeditationForm
            initialValues={meditationData}
            closeDialog={closeDialog}
            onSubmit={submit}
          />
        </DialogContent>
      </Dialog>

      <Dialog open={isDeleteDialogOpen} onClose={closeDeleteDialog} className="meditations-dialog">
        <DialogTitle>Delete Meditation</DialogTitle>
        <DialogContent>
          <p>Are you sure you want to delete this Meditation?</p>
          <div className="text-center customForm">
            <button
              type="button"
              onClick={closeDeleteDialog}
              className="submit-button cancel-button"
            >
              No
            </button>
            <button type="button" className="submit-button" onClick={confirmDelete}>
              Yes
            </button>
          </div>
        </DialogContent>
      </Dialog>

      <Dialog
        open={isDialogForMultipleUsersOpen}
        onClose={closeDialogForMultipleUsers}
        className="notifications-dialog"
      >
        <DialogTitle>Send Notification To Multiple Users</DialogTitle>
        <DialogContent>
          <CustomNotificationForm
            initialValues={notificationData}
            closeDialog={closeDialogForMultipleUsers}
            onSubmit={submitNotificationForMultipleUsers}
            allMembersForNotification={allMembersForNotification}
            getAllMembersForNotification={getAllMembersForNotification}
            notificationType="MEDITATION"
            sendingMode={sendingMode}
            setSendingMode={setSendingMode}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
}

Meditations.propTypes = {
  addMeditation: PropTypes.func,
  updateMeditation: PropTypes.func,
  deleteMeditation: PropTypes.func,
  getAllMeditations: PropTypes.func,
  allMeditations: PropTypes.arrayOf(PropTypes.shape({})),
  getAllMembersForNotification: PropTypes.func,
  allMembersForNotification: PropTypes.arrayOf(PropTypes.shape({})),
  createCustomNotification: PropTypes.func,
};

Meditations.defaultProps = {
  addMeditation: undefined,
  updateMeditation: undefined,
  deleteMeditation: undefined,
  getAllMeditations: undefined,
  allMeditations: undefined,
  getAllMembersForNotification: undefined,
  allMembersForNotification: undefined,
  createCustomNotification: undefined,
};

const mapStateToProps = (state) => ({
  loading: state.loading,
  allMeditations: state.allMeditations,
  allMembersForNotification: state.allMembersForNotification,
});

const actionsStateToProps = {
  addMeditation: (data) => ({ type: MEDITATION_ADD_REQUEST, data }),
  updateMeditation: (data) => ({ type: MEDITATION_UPDATE_REQUEST, data }),
  deleteMeditation: (id) => ({ type: MEDITATION_DELETE_REQUEST, id }),
  getAllMeditations: () => ({ type: GET_ALL_MEDITATIONS_REQUEST }),
  getAllMembersForNotification: (data) => ({
    type: GET_ALL_MEMBERS_FOR_NOTIFICATION_REQUEST,
    data,
  }),
  createCustomNotification: (data) => ({ type: CREATE_CUSTOM_NOTIFICATION, data }),
};

export default connect(mapStateToProps, actionsStateToProps)(Meditations);
