import React, { useEffect, useState } from 'react';
import Paper from '@material-ui/core/Paper';
import { PieChart } from 'react-minimal-pie-chart';
import Slider from 'react-slick';

function Dashboard() {
  const [meditationData, setMeditationData] = useState([]);
  const [instructorData, setInstructorData] = useState([]);
  const [totalUsers] = useState(200);
  const [usersPieChartData, setUsersPieChartData] = useState([]);
  const [selected, setSelected] = useState(0);
  const [salesPieChartData, setSalesPieChartData] = useState([]);
  const [totalAmnt] = useState(2000);

  useEffect(() => {
    setMeditationData([
      { name: 'Cobra Pineal Breath', fileLocation: 'meditations/2qqpb46k9s4ufr1.png' },
      { name: 'Binaural', fileLocation: 'meditations/2qqpntvkbl9oc3g.jpg' },
      { name: 'Guided', fileLocation: 'meditations/2qqpntvkbl6ktml.jpg' },
      { name: 'Chants', fileLocation: 'meditations/2qqpntvkbmb6vnx.jpg' },
      { name: 'Chants', fileLocation: 'meditations/2qqpntvkbmb6vnx.jpg' },
      { name: 'Chants', fileLocation: 'meditations/2qqpntvkbmb6vnx.jpg' },
      { name: 'Chants', fileLocation: 'meditations/2qqpntvkbmb6vnx.jpg' },
      { name: 'Chants', fileLocation: 'meditations/2qqpntvkbmb6vnx.jpg' },
      { name: 'Chants', fileLocation: 'meditations/2qqpntvkbmb6vnx.jpg' },
      { name: 'Chants', fileLocation: 'meditations/2qqpntvkbmb6vnx.jpg' },
    ]);
  }, []);

  useEffect(() => {
    setInstructorData([
      {
        name: 'Dr. Arielle Schwartz',
        profileImageURL: 'https://public-dev.retreatsounds.com/thumbnail/40599viki8qus5l.jpeg',
        type: 'Instructor',
      },
      {
        name: 'Dr. Arielle Schwartz',
        profileImageURL: 'https://public-dev.retreatsounds.com/thumbnail/40599viki8qus5l.jpeg',
        type: 'Instructor',
      },
      {
        name: 'Dr. Arielle Schwartz',
        profileImageURL: 'https://public-dev.retreatsounds.com/thumbnail/40599viki8qus5l.jpeg',
        type: 'Instructor',
      },
      {
        name: 'Dr. Arielle Schwartz',
        profileImageURL: 'https://public-dev.retreatsounds.com/thumbnail/40599viki8qus5l.jpeg',
        type: 'Instructor',
      },
      {
        name: 'Dr. Arielle Schwartz',
        profileImageURL: 'https://public-dev.retreatsounds.com/thumbnail/40599viki8qus5l.jpeg',
        type: 'Instructor',
      },
    ]);
  }, []);

  useEffect(() => {
    setUsersPieChartData([
      { title: 'Free Users', value: 40, percent: 20, color: '#692236' },
      { title: 'Monthly Premium Users', value: 100, percent: 50, color: '#F19C44' },
      { title: 'Annual Premium Users', value: 50, percent: 25, color: '#BF3D3B' },
      { title: 'Lifetime Premium Users', value: 10, percent: 5, color: '#44F194' },
    ]);
  }, []); 
  useEffect(() => {
    setSalesPieChartData([
      { title: 'Play Store Earnings', value: 400, percent: 20, color: '#692236' },
      { title: 'App Store Earnings', value: 400, percent: 20, color: '#F19C44' },
      { title: 'Stripe Earnings', value: 1200, percent: 60, color: '#BF3D3B' },
    ]);
  }, []);

  const selectThisSegment = (index) => {
    const updatedUsersPieChartData = [...usersPieChartData];
    updatedUsersPieChartData[index].isFocussed = true;
    setUsersPieChartData(updatedUsersPieChartData);
  };

  const deselectThisSegment = (index) => {
    const updatedUsersPieChartData = [...usersPieChartData];
    updatedUsersPieChartData[index].isFocussed = false;
    setUsersPieChartData(updatedUsersPieChartData);
  };
  const selectThisSalesSegment = (index) => {
    const updatedSalesPieChartData = [...salesPieChartData];
    updatedSalesPieChartData[index].isFocussed = true;
    setSalesPieChartData(updatedSalesPieChartData);
  };

  const deselectThisSalesSegment = (index) => {
    const updatedSalesPieChartData = [...salesPieChartData];
    updatedSalesPieChartData[index].isFocussed = false;
    setSalesPieChartData(updatedSalesPieChartData);
  };
  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: 'block', background: 'black' }}
        onClick={onClick}
      />
    );
  }
  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: 'block', background: 'black' }}
        onClick={onClick}
      />
    );
  }
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };

  return (
    <div className="upload">
      <div className="page-title">
        <h1>Top 10 most liked meditations</h1>
      </div>
      <div className="meditation-container">
        <Slider {...settings}>
          {meditationData?.map((data) => (
            <div className="meditation-outer">
              <Paper className="paper meditation">
                <div className="meditation-inner">
                  <div className="img-container">
                    <div className="dummy" />
                    <div
                      className="img-box"
                      style={{
                        backgroundImage: `url(https://retreat-content-test.s3.amazonaws.com/${data.fileLocation})`,
                      }}
                    />
                  </div>
                  <div className="text-center meditation-title">{data.name}</div>
                </div>
              </Paper>
            </div>
          ))}
        </Slider>
      </div>
      <div className="page-title">
        <h1>Top 5 most Followed Instructor & Arist</h1>
      </div>
      <div className="meditation-container">
        {instructorData?.map((data) => (
          <div className="meditation-outer">
            <Paper className="paper meditation">
              <div className="meditation-inner">
                <div className="img-container">
                  <div className="dummy" />
                  <div
                    className="img-box"
                    style={{ backgroundImage: `url(${data.profileImageURL})` }}
                  />
                </div>
                <div className="text-center instructor-title">{data.name}</div>
                <div className="text-center instructor-type">{data.type}</div>
              </div>
            </Paper>
          </div>
        ))}
      </div>
      <div className="page-title">
        <h1>Users</h1>
      </div>
      <Paper className="paper">
        <div className="userContainer">
          <div className="pieChartContainer">
            {usersPieChartData &&
            Array.isArray(usersPieChartData) &&
            usersPieChartData.length > 0 ? (
              <PieChart
                data={usersPieChartData}
                radius={PieChart.defaultProps.radius - 6}
                segmentsStyle={{ transition: 'stroke .3s', cursor: 'pointer' }}
                lineWidth={70}
                animate
                label={({ dataEntry }) => `${dataEntry.value}`}
                onMouseOver={(e, index) => selectThisSegment(index)}
                onFocus={(e, index) => selectThisSegment(index)}
                onMouseOut={(e, index) => deselectThisSegment(index)}
                onBlur={(e, index) => deselectThisSegment(index)}
                labelStyle={() => ({
                  fill: '#fff',
                  // opacity: 0.75,
                  pointerEvents: 'none',
                  fontSize: 5,
                })}
                labelPosition={60}
                onClick={(e, index) => {
                  setSelected(index === selected ? undefined : index);
                }}
                totalValue={200}
              />
            ) : null}
          </div>
          <div className="userDataContainer">
            <div>
              <div className="totalUser">{totalUsers}</div>
              <div className="totalUserLabel">Total Users</div>
            </div>
            <div className="otherUserDataContainer">
              {usersPieChartData && Array.isArray(usersPieChartData) && usersPieChartData.length > 0
                ? usersPieChartData.map((item) => (
                    <div
                      className={`chart-data-item-cont ${
                        usersPieChartData.findIndex((itemSelected) => itemSelected.isFocussed) >=
                          0 && !item.isFocussed
                          ? 'chart-data-item-cont-opacity'
                          : 'chart-data-item-cont-normal'
                      }`}
                      style={item.isFocussed ? { transform: 'scale(1.1)' } : null}
                    >
                      <div className="data" style={{ color: item.color }}>
                        {item.value}
                      </div>
                      <div className="label">{item.title}</div>
                    </div>
                  ))
                : null}
            </div>
          </div>
        </div>
      </Paper>
      <div className="page-title">
        <h1>Sales</h1>
      </div>
      <Paper className="paper">
        <div className="userContainer">
          <div className="salesDataContainer">
            <div>
              <div className="totalUser">${totalAmnt}</div>
              <div className="totalUserLabel">Total Amount Earned</div>
            </div>
            <div className="otherUserDataContainer">
              {salesPieChartData && Array.isArray(salesPieChartData) && salesPieChartData.length > 0
                ? salesPieChartData.map((item) => (
                    <div
                      className={`chart-data-item-cont ${
                        salesPieChartData.findIndex((itemSelected) => itemSelected.isFocussed) >=
                          0 && !item.isFocussed
                          ? 'chart-data-item-cont-opacity'
                          : 'chart-data-item-cont-normal'
                      }`}
                      style={item.isFocussed ? { transform: 'scale(1.1)' } : null}
                    >
                      <div className="data" style={{ color: item.color }}>
                        ${item.value}
                      </div>
                      <div className="label">{item.title}</div>
                    </div>
                  ))
                : null}
            </div>
          </div>
          <div className="pieChartContainer">
            {salesPieChartData &&
            Array.isArray(salesPieChartData) &&
            salesPieChartData.length > 0 ? (
              <PieChart
                data={salesPieChartData}
                radius={PieChart.defaultProps.radius - 6}
                segmentsStyle={{ transition: 'stroke .3s', cursor: 'pointer' }}
                lineWidth={70}
                animate
                // viewBoxSize={[368, 368]}
                label={({ dataEntry }) => `${dataEntry.value}`}
                // segmentsShift={index => (index === selected ? 6 : 1)}
                // labelPosition={100 - 60 / 2}
                onMouseOver={(e, index) => selectThisSalesSegment(index)}
                onFocus={(e, index) => selectThisSalesSegment(index)}
                onMouseOut={(e, index) => deselectThisSalesSegment(index)}
                onBlur={(e, index) => deselectThisSalesSegment(index)}
                labelStyle={() => ({
                  fill: '#fff',
                  // opacity: 0.75,
                  pointerEvents: 'none',
                  fontSize: 5,
                })}
                labelPosition={60}
                onClick={(_, index) => {
                  setSelected(index === selected ? undefined : index);
                }}
                totalValue={2000}
              />
            ) : null}
          </div>
        </div>
      </Paper>
    </div>
  );
}

export default Dashboard;
