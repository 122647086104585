import React from 'react';

import { Paper } from '@material-ui/core';
import RemindPasswordForm from './RemindPasswordForm';
import history from '../../history';

import logo from '../../assets/images/logo.png';

function RemindPassword() {
  const submit = (values) => {
    console.log('sending data for restoring password: ', values);
    history.push('/login/');
  };

  return (
    <div className="remind-password-page">
      <div>
        <img src={logo} alt="" className="logo" />
        <Paper className="paper">
          <RemindPasswordForm onSubmit={submit} />
        </Paper>
      </div>
    </div>
  );
}

export default RemindPassword;
