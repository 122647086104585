import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Pagination from '@material-ui/lab/Pagination';
import { RiUserVoiceLine } from 'react-icons/ri';
import SelectField from '../common/SelectField';
import { Grid } from '@material-ui/core';
import { Field, Form } from 'redux-form';
import TextField from '../common/TextField';
import FileField from '../common/FileField';

import NotificationForm from './NotificationForm';

import icoAdd from '../../assets/images/icons/ico-add.svg';

import {
  NOTIFICATION_ADD_REQUEST,
  NOTIFICATION_UPDATE_REQUEST,
  NOTIFICATION_DELETE_REQUEST,
  GET_ALL_NOTIFICATIONS_REQUEST,
  GET_ALL_MEMBERS_FOR_NOTIFICATION_REQUEST,
  NOTIFICATION_ADD_FOR_MEMBER_REQUEST,
  GROUP_NOTIFICATION_ADD_REQUEST,
  GET_ALL_GROUPS_SEGMENTS_SUCCESS,
} from '../../redux/actions/actionTypes';
import NotificationFormForMultipleUsers from '../Notifications/NotificationFormForMultipleUsers';
const required = (value) => (value && value !== '' ? undefined : 'Required');
function GroupNotifications(props) {
  const {
    addGroupNotification,
    updateNotification,
    deleteNotification,
    getAllNotifications,
    getAllGroupSegments,
    allNotifications,
    getAllMembersForNotification,
    allMembersForNotification,
    addNotificationForMember,
    createGroupNotification,
    allGroupSegments,
  } = props;

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isDialogForMultipleUsersOpen, setIsDialogForMultipleUsersOpen] = useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [notificationData, setNotificationData] = useState();

  // Pagination
  const [activePage, setActivePage] = useState(1);
  const [pagesCount, setPagesCount] = useState(1);
  const [itemsPerPage] = useState(10);

  useEffect(() => {
    if (allNotifications) {
      setPagesCount(Math.ceil(allNotifications.length / itemsPerPage));
    }
  }, [allNotifications, itemsPerPage]);
  useEffect(() => {
    if (activePage > pagesCount) {
      setActivePage(1);
    }
  }, [pagesCount, activePage]);

  const handlePageChange = (event, value) => {
    setActivePage(value);
  };

  // Get Notifications List
  useEffect(() => {
    getAllNotifications();
  }, [getAllNotifications]);

  useEffect(() => {
    getAllGroupSegments();
  }, [getAllGroupSegments]);

  // Add/Update Notification
  const openDialog = (data) => {
    if (data.title) {
      setNotificationData(data);
    }
    setIsDialogOpen(true);
  };

  useEffect(() => {
    const data = {
      name: null,
    };
    getAllMembersForNotification(data);
  }, [getAllMembersForNotification]);

  const openDialogForMultipleUsers = (data) => {
    if (data.title) {
      setNotificationData(data);
    }
    setIsDialogForMultipleUsersOpen(true);
  };

  const closeDialog = () => {
    setIsDialogOpen(false);
    setNotificationData(undefined);
  };

  const closeDialogForMultipleUsers = () => {
    setIsDialogForMultipleUsersOpen(false);
    setNotificationData(undefined);
  };

  const submit = (values) => {
    console.log('[NOTIFICATION_TYPE values]', values);
    addGroupNotification(values);
    closeDialog();
  };

  const closeDeleteDialog = () => {
    setIsDeleteDialogOpen(false);
    setNotificationData(undefined);
  };

  const confirmDelete = () => {
    // eslint-disable-next-line
    deleteNotification({ code: notificationData['code'] });
    closeDeleteDialog();
  };

  return (
    <div className="notifications memberships">
      <div className="page-title">
        <h1>Group Notifications</h1>
      </div>
      {/* --------------------------------- */}

      {/* --------------------------------- */}
      <Paper className="paper" style={{ marginBottom: 20, padding: 20 }}>
        {/* <NotificationForm
          initialValues={notificationData}
          closeDialog={closeDialog}
          onSubmit={submit}
        /> */}
        <NotificationForm
          initialValues={notificationData}
          closeDialog={closeDialog}
          onSubmit={submit}
          allMemberships={allGroupSegments}
        />
        {/* ------------------------------------------ */}

        <Table stickyHeader className="table"></Table>
      </Paper>
    </div>
  );
}

GroupNotifications.propTypes = {
  addNotification: PropTypes.func,
  updateNotification: PropTypes.func,
  deleteNotification: PropTypes.func,
  getAllNotifications: PropTypes.func,
  getAllGroupSegments: PropTypes.func,
  allNotifications: PropTypes.arrayOf(PropTypes.shape({})),
  getAllMembersForNotification: PropTypes.func,
  allMembersForNotification: PropTypes.arrayOf(PropTypes.shape({})),
  allGroupSegments: PropTypes.arrayOf(PropTypes.shape({})),
  addNotificationForMember: PropTypes.func,
  addGroupNotification: PropTypes.func,
  createGroupNotification: PropTypes.func,
};

GroupNotifications.defaultProps = {
  addNotification: undefined,
  updateNotification: undefined,
  deleteNotification: undefined,
  getAllNotifications: undefined,
  getAllGroupSegments: undefined,
  allNotifications: undefined,
  getAllMembersForNotification: undefined,
  allMembersForNotification: undefined,
  addNotificationForMember: undefined,
  addGroupNotification: undefined,
  createGroupNotification: undefined,
  allGroupSegments: undefined,
};

const mapStateToProps = (state) => ({
  loading: state.loading,
  allNotifications: state.allNotifications,
  allMembersForNotification: state.allMembersForNotification,
  allGroupSegments: state.allGroupSegments,
});

const actionsStateToProps = {
  addNotification: (data) => ({ type: NOTIFICATION_ADD_REQUEST, data }),
  updateNotification: (data) => ({ type: NOTIFICATION_UPDATE_REQUEST, data }),
  deleteNotification: (data) => ({ type: NOTIFICATION_DELETE_REQUEST, data }),
  getAllNotifications: () => ({ type: GET_ALL_NOTIFICATIONS_REQUEST }),
  getAllMembersForNotification: (data) => ({
    type: GET_ALL_MEMBERS_FOR_NOTIFICATION_REQUEST,
    data,
  }),
  addNotificationForMember: (data) => ({ type: NOTIFICATION_ADD_FOR_MEMBER_REQUEST, data }),
  addGroupNotification: (data) => ({ type: GROUP_NOTIFICATION_ADD_REQUEST, data }),
  getAllGroupSegments: () => ({ type: GET_ALL_GROUPS_SEGMENTS_SUCCESS }),
};

export default connect(mapStateToProps, actionsStateToProps)(GroupNotifications);
