import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import uploadImg from '../../assets/images/upload-img.png';
import icoColoredVideoCamera from '../../assets/images/icons/ico-colored-video-camera.svg';
import icoColoredMicrophone from '../../assets/images/icons/ico-colored-microphone.svg';
import { RELOAD_REQUEST } from '../../redux/actions/actionTypes';

function Step1(props) {
  const { reload } = props;
  useEffect(() => {
    reload();
  });

  return (
    <div className="upload-step1">
      <div className="text-center">
        <img src={uploadImg} alt="" className="upload-img" />
        <div className="clearfix" />

        <div
          className="upload-type-block"
          role="presentation"
          onClick={() => props.activeStepChange(2, 'Video')}
        >
          <img src={icoColoredVideoCamera} alt="" />
          <div className="upload-type-title">Upload videos</div>
        </div>
        <div
          className="upload-type-block"
          role="presentation"
          onClick={() => props.activeStepChange(2, 'Audio')}
        >
          <img src={icoColoredMicrophone} alt="" />
          <div className="upload-type-title">Upload audios</div>
        </div>
      </div>
    </div>
  );
}

Step1.propTypes = {
  activeStepChange: PropTypes.func.isRequired,
  reload: PropTypes.func.isRequired,
};

const actionsStateToProps = {
  reload: () => ({ type: RELOAD_REQUEST }),
};

export default connect(undefined, actionsStateToProps)(Step1);
