import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import { staticResourceAwsBaseUrl } from '../../utils/helpers';
import uploadTypeImg from '../../assets/images/upload-type-img.png';

import { GET_SINGLE_AUDIO_DETAILS_REQUEST } from '../../redux/actions/actionTypes';

function AudioDetails(props) {
  const { id } = useParams();
  const { getAudioDetails, audioDetails } = props;

  useEffect(() => {
    getAudioDetails(id);
  }, [getAudioDetails]);

  return (
    <div className="audio-details">
      <div className="page-title">
        <h1>
          {audioDetails && audioDetails.data && audioDetails.data.name
            ? audioDetails.data.name
            : 'Audio Details'}
        </h1>
      </div>
      <Paper className="paper">
        <div className="row">
          <div className="col">
            <img src={uploadTypeImg} alt="" className="audio-page-bg" />
            <div className="audio-container">
              {/* eslint-disable-next-line */}
              <audio className="audio-player" controls>
                {audioDetails && audioDetails.contentUrl ? (
                  <source src={audioDetails.contentUrl} />
                ) : null}
              </audio>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <div className="title">Audio Name</div>
            <div className="value">
              {audioDetails && audioDetails.data && audioDetails.data.name
                ? audioDetails.data.name
                : ''}
            </div>
          </div>
          <div className="col">
            <div className="title">Description</div>
            <div className="value">
              {audioDetails && audioDetails.data && audioDetails.data.description
                ? audioDetails.data.description
                : ''}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <div className="title">Thumbnail</div>
            <div className="value">
              <img
                alt="thumbnail"
                src={`${staticResourceAwsBaseUrl}/${
                  audioDetails && audioDetails.data && audioDetails.data.thumbnail
                    ? audioDetails.data.thumbnail
                    : ''
                }`}
                className="thumbnail"
              />
            </div>
          </div>
          <div className="col">
            <div className="title">Animation</div>
            <div className="value">
              <img
                alt="animation"
                src={`${staticResourceAwsBaseUrl}/${
                  audioDetails && audioDetails.data && audioDetails.data.animation
                    ? audioDetails.data.animation
                    : ''
                }`}
                className="thumbnail"
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <div className="title">Artist</div>
            <div className="value">
              {audioDetails && audioDetails.data && audioDetails.data.artist
                ? audioDetails.data.artist
                : ''}
            </div>
          </div>
          <div className="col">
            <div className="title">Composer</div>
            <div className="value">
              {audioDetails && audioDetails.data && audioDetails.data.composer
                ? audioDetails.data.composer
                : ''}
            </div>
          </div>
          <div className="col">
            <div className="title">Duration</div>
            <div className="value">
              {audioDetails && audioDetails.data && audioDetails.data.duration
                ? audioDetails.data.duration
                : ''}{' '}
              sec
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <div className="title">Series</div>
            <div className="value">
              {audioDetails && audioDetails.series && audioDetails.series.name
                ? audioDetails.series.name
                : ''}
            </div>
          </div>
          <div className="col">
            <div className="title">Memberships</div>
            <div className="value">
              {audioDetails &&
              audioDetails.memberships &&
              audioDetails.memberships.constructor === Array
                ? audioDetails.memberships.map((membership) => membership.name).join(', ')
                : ''}
            </div>
          </div>
          <div className="col">
            <div className="title">Meditation</div>
            <div className="value">
              {audioDetails && audioDetails.meditation && audioDetails.meditation.name
                ? audioDetails.meditation.name
                : ''}
            </div>
          </div>
        </div>
      </Paper>
    </div>
  );
}

AudioDetails.propTypes = {
  audioDetails: PropTypes.shape({
    data: PropTypes.shape({
      name: PropTypes.string,
      description: PropTypes.string,
      thumbnail: PropTypes.string,
      animation: PropTypes.string,
      artist: PropTypes.string,
      composer: PropTypes.string,
      duration: PropTypes.number,
    }),
    series: PropTypes.shape({
      name: PropTypes.string,
    }),
    meditation: PropTypes.shape({
      name: PropTypes.string,
    }),
    memberships: PropTypes.arrayOf(PropTypes.shape({})),
    contentUrl: PropTypes.string,
  }),
  getAudioDetails: PropTypes.func,
};

AudioDetails.defaultProps = {
  audioDetails: undefined,
  getAudioDetails: undefined,
};

const mapStateToProps = (state) => ({
  audioDetails: state.audioDetails,
});

const actionsStateToProps = {
  getAudioDetails: (id) => ({ type: GET_SINGLE_AUDIO_DETAILS_REQUEST, id }),
};

export default connect(mapStateToProps, actionsStateToProps)(AudioDetails);
