import { call, put } from 'redux-saga/effects';
import axios from './axiosHandler';
import { API_HOST } from '../env';
import sagaLoading from './sagaLoading';
import { MESSAGE_ERROR, GET_ALL_AUDIOS_SUCCESS } from '../actions/actionTypes';

export default function* sagaGetAllAudios(data) {
  console.log('DATA data', data);
  const { limit, page, query, cancelToken } = data.data;
  try {
    yield call(sagaLoading, true);
    let url = `${API_HOST}content/list?limit=${limit || 10}&pageNumber=${page || 1}`;
    if (query) {
      url += `&query=${query}`;
    }
    const obj = {
      url,
      method: 'get',
    };
    if (cancelToken) {
      obj.cancelToken = cancelToken;
    }
    const resData = yield call(() => axios(obj).then((response) => response.data));
    console.log('DATA resData', resData);
    yield put({ type: GET_ALL_AUDIOS_SUCCESS, payload: resData.data });
    yield call(sagaLoading, false);
  } catch (error) {
    yield call(sagaLoading, false);
    if (error.toString() !== 'Cancel') {
      yield put({
        type: MESSAGE_ERROR,
        payload: error.message ? error.message : 'There is something wrong, please, try again.',
      });
    }
  }
}
