import { takeEvery } from 'redux-saga/effects';
import {
  RELOAD_REQUEST,
  LOGIN_REQUEST,
  MEMBERSHIP_ADD_REQUEST,
  MEMBERSHIP_UPDATE_REQUEST,
  MEMBERSHIP_DELETE_REQUEST,
  GET_ALL_MEMBERSHIPS_REQUEST,
  COUPON_ADD_REQUEST,
  COUPON_UPDATE_REQUEST,
  COUPON_DELETE_REQUEST,
  GET_ALL_COUPONS_REQUEST,
  NOTIFICATION_ADD_REQUEST,
  NOTIFICATION_UPDATE_REQUEST,
  NOTIFICATION_DELETE_REQUEST,
  GET_ALL_NOTIFICATIONS_REQUEST,
  ENTITY_ADD_REQUEST,
  ENTITY_UPDATE_REQUEST,
  ENTITY_DELETE_REQUEST,
  GET_ALL_ENTITYS_REQUEST,
  GROUP_ADD_REQUEST,
  GROUP_UPDATE_REQUEST,
  GROUP_DELETE_REQUEST,
  GET_ALL_GROUPS_REQUEST,
  UPLOAD_SINGLE_REQUEST,
  UPLOAD_SERIES_REQUEST,
  GET_ALL_CONTENT_REQUEST,
  GET_ALL_SERIES_REQUEST,
  GET_ALL_PROGRAMS_REQUEST,
  SINGLE_UPDATE_REQUEST,
  SINGLE_DELETE_REQUEST,
  SERIES_UPDATE_REQUEST,
  SERIES_DELETE_REQUEST,
  MEMBERS_ADD_REQUEST,
  GET_SINGLE_AUDIO_DETAILS_REQUEST,
  MEMBERS_UPDATE_REQUEST,
  MEMBERS_DELETE_REQUEST,
  GET_ALL_MEMBERS_REQUEST,
  GET_ALL_MEDITATIONS_REQUEST,
  MEDITATION_ADD_REQUEST,
  MEDITATION_UPDATE_REQUEST,
  MEDITATION_DELETE_REQUEST,
  TAG_ADD_REQUEST,
  TAG_UPDATE_REQUEST,
  GET_ALL_TAGS_REQUEST,
  NOTIFICATION_ADD_FOR_MEMBER_REQUEST,
  GET_ALL_MEMBERS_FOR_NOTIFICATION_REQUEST,
  GET_ALL_PARENTS_REQUEST,
  GET_ALL_AUDIOS_REQUEST,
  CREATE_CUSTOM_NOTIFICATION,
  GROUP_NOTIFICATION_ADD_REQUEST,
  GET_ALL_GROUPS_SEGMENTS_SUCCESS,
} from '../actions/actionTypes';

import sagaReload from './sagaReload';
import sagaLogin from './sagaLogin';
import sagaAddMembership from './sagaAddMembership';
import sagaUpdateMembership from './sagaUpdateMembership';
import sagaDeleteMembership from './sagaDeleteMembership';
import sagaGetAllMemberships from './sagaGetAllMemberships';
import sagaAddCoupon from './sagaAddCoupon';
import sagaUpdateCoupon from './sagaUpdateCoupon';
import sagaDeleteCoupon from './sagaDeleteCoupon';
import sagaGetAllCoupons from './sagaGetAllCoupons';
import sagaAddNotification from './sagaAddNotification';
import sagaUpdateNotification from './sagaUpdateNotification';
import sagaDeleteNotification from './sagaDeleteNotification';
import sagaGetAllNotifications from './sagaGetAllNotifications';
import sagaAddEntity from './sagaAddEntity';
import sagaUpdateEntity from './sagaUpdateEntity';
import sagaDeleteEntity from './sagaDeleteEntity';
import sagaGetAllEntities from './sagaGetAllEntities';
import sagaAddGroup from './sagaAddGroup';
import sagaUpdateGroup from './sagaUpdateGroup';
import sagaDeleteGroup from './sagaDeleteGroup';
import sagaGetAllGroups from './sagaGetAllGroups';
import sagaAddMeditation from './sagaAddMeditation';
import sagaUpdateMeditation from './sagaUpdateMeditation';
import sagaDeleteMeditation from './sagaDeleteMeditation';
import sagaGetAllMeditations from './sagaGetAllMeditations';
import sagaUploadSingle from './sagaUploadSingle';
import sagaUploadSeries from './sagaUploadSeries';
import sagaGetAllContent from './sagaGetAllContent';
import sagaGetAllSeries from './sagaGetAllSeries';
import sagaGetAllPrograms from './sagaGetAllPrograms';
import sagaUpdateSingle from './sagaUpdateSingle';
import sagaDeleteSingle from './sagaDeleteSingle';
import sagaUpdateSeries from './sagaUpdateSeries';
import sagaDeleteSeries from './sagaDeleteSeries';
import sagaAddMembers from './sagaAddMembers';
import sagaUpdateMembers from './sagaUpdateMembers';
import sagaDeleteMembers from './sagaDeleteMembers';
import sagaGetAllMembers from './sagaGetAllMembers';
import sagaGetAudioDetails from './sagaGetAudioDetails';
import sagaAddTag from './sagaAddTag';
import sagaUpdateTag from './sagaUpdateTag';
import sagaGetAllTags from './sagaGetAllTags';
import sagaAddNotificationForMember from './sagaAddNotificationForMember';
import sagaCreateCustomNotification from './sagaCreateCustomNotification';
import sagaGetAllMembersForNotification from './sagaGetAllMembersForNotification';
import sagaGetAllParents from './sagaGetAllParents';
import sagaGetAllAudios from './sagaGetAllAudios';
import sagaAddGroupNotification from './sagaAddGroupNotification';
import sagaGetAllGroupSegments from './sagaGetAllGroupSegments';

export default function* sagas() {
  yield takeEvery(RELOAD_REQUEST, sagaReload);
  yield takeEvery(LOGIN_REQUEST, sagaLogin);
  yield takeEvery(MEMBERSHIP_ADD_REQUEST, sagaAddMembership);
  yield takeEvery(MEMBERSHIP_UPDATE_REQUEST, sagaUpdateMembership);
  yield takeEvery(MEMBERSHIP_DELETE_REQUEST, sagaDeleteMembership);
  yield takeEvery(GET_ALL_MEMBERSHIPS_REQUEST, sagaGetAllMemberships);
  yield takeEvery(COUPON_ADD_REQUEST, sagaAddCoupon);
  yield takeEvery(COUPON_UPDATE_REQUEST, sagaUpdateCoupon);
  yield takeEvery(COUPON_DELETE_REQUEST, sagaDeleteCoupon);
  yield takeEvery(GET_ALL_COUPONS_REQUEST, sagaGetAllCoupons);
  yield takeEvery(NOTIFICATION_ADD_REQUEST, sagaAddNotification);
  yield takeEvery(NOTIFICATION_UPDATE_REQUEST, sagaUpdateNotification);
  yield takeEvery(NOTIFICATION_DELETE_REQUEST, sagaDeleteNotification);
  yield takeEvery(GET_ALL_NOTIFICATIONS_REQUEST, sagaGetAllNotifications);
  yield takeEvery(ENTITY_ADD_REQUEST, sagaAddEntity);
  yield takeEvery(ENTITY_UPDATE_REQUEST, sagaUpdateEntity);
  yield takeEvery(ENTITY_DELETE_REQUEST, sagaDeleteEntity);
  yield takeEvery(GET_ALL_ENTITYS_REQUEST, sagaGetAllEntities);
  yield takeEvery(GROUP_ADD_REQUEST, sagaAddGroup);
  yield takeEvery(GROUP_UPDATE_REQUEST, sagaUpdateGroup);
  yield takeEvery(GROUP_DELETE_REQUEST, sagaDeleteGroup);
  yield takeEvery(GET_ALL_GROUPS_REQUEST, sagaGetAllGroups);
  yield takeEvery(MEDITATION_ADD_REQUEST, sagaAddMeditation);
  yield takeEvery(MEDITATION_UPDATE_REQUEST, sagaUpdateMeditation);
  yield takeEvery(MEDITATION_DELETE_REQUEST, sagaDeleteMeditation);
  yield takeEvery(GET_ALL_MEDITATIONS_REQUEST, sagaGetAllMeditations);
  yield takeEvery(UPLOAD_SINGLE_REQUEST, sagaUploadSingle);
  yield takeEvery(UPLOAD_SERIES_REQUEST, sagaUploadSeries);
  yield takeEvery(GET_ALL_CONTENT_REQUEST, sagaGetAllContent);
  yield takeEvery(GET_ALL_SERIES_REQUEST, sagaGetAllSeries);
  yield takeEvery(GET_ALL_PROGRAMS_REQUEST, sagaGetAllPrograms);
  yield takeEvery(SINGLE_UPDATE_REQUEST, sagaUpdateSingle);
  yield takeEvery(SINGLE_DELETE_REQUEST, sagaDeleteSingle);
  yield takeEvery(SERIES_UPDATE_REQUEST, sagaUpdateSeries);
  yield takeEvery(SERIES_DELETE_REQUEST, sagaDeleteSeries);
  yield takeEvery(MEMBERS_ADD_REQUEST, sagaAddMembers);
  yield takeEvery(MEMBERS_UPDATE_REQUEST, sagaUpdateMembers);
  yield takeEvery(MEMBERS_DELETE_REQUEST, sagaDeleteMembers);
  yield takeEvery(GET_ALL_MEMBERS_REQUEST, sagaGetAllMembers);
  yield takeEvery(GET_SINGLE_AUDIO_DETAILS_REQUEST, sagaGetAudioDetails);
  yield takeEvery(TAG_ADD_REQUEST, sagaAddTag);
  yield takeEvery(TAG_UPDATE_REQUEST, sagaUpdateTag);
  yield takeEvery(GET_ALL_TAGS_REQUEST, sagaGetAllTags);
  yield takeEvery(NOTIFICATION_ADD_FOR_MEMBER_REQUEST, sagaAddNotificationForMember);
  yield takeEvery(CREATE_CUSTOM_NOTIFICATION, sagaCreateCustomNotification);
  yield takeEvery(GET_ALL_MEMBERS_FOR_NOTIFICATION_REQUEST, sagaGetAllMembersForNotification);
  yield takeEvery(GET_ALL_PARENTS_REQUEST, sagaGetAllParents);
  yield takeEvery(GROUP_NOTIFICATION_ADD_REQUEST, sagaAddGroupNotification);
  yield takeEvery(GET_ALL_AUDIOS_REQUEST, sagaGetAllAudios);
  yield takeEvery(GET_ALL_GROUPS_SEGMENTS_SUCCESS, sagaGetAllGroupSegments);
}
