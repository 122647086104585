import React, { useState } from 'react';
import Paper from '@material-ui/core/Paper';

import Step1 from './Step1';
import Step3 from './Step3';

function Upload() {
  const [activeStep, setActiveStep] = useState(1);
  const [uploadFormat, setUploadFormat] = useState();
  const [uploadType, setUploadType] = useState();

  const activeStepChange = (stepNum, format) => {
    setActiveStep(stepNum);
    if (format) {
      setUploadFormat(format);
      setUploadType('single');
    }
  };

  let step = <Step1 activeStepChange={activeStepChange} />;
  if (activeStep === 2) {
    step = <Step3 uploadFormat={uploadFormat} uploadType={uploadType} />;
  }
  // else if (activeStep === 3) {
  //   step = <Step2 activeStepChange={activeStepChange} />;
  // }

  return (
    <div className="upload">
      <div className="page-title">
        <h1>{uploadType ? `Upload ${uploadType}` : 'Upload'}</h1>
      </div>

      <div className="text-center">
        <div className="steps-navi">
          <div className="step-navi active">
            <span>1</span>
          </div>
          <div className={activeStep >= 2 ? 'step-navi-path active' : 'step-navi-path'} />
          <div className={activeStep >= 2 ? 'step-navi active' : 'step-navi'}>
            <span>2</span>
          </div>
          {/* <div className={activeStep >= 3 ? 'step-navi-path active' : 'step-navi-path'} />
          <div className={activeStep >= 3 ? 'step-navi active' : 'step-navi'}>
            <span>3</span>
          </div> */}
        </div>
      </div>

      <Paper className="paper">{step}</Paper>
    </div>
  );
}

export default Upload;
