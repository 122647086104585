import React from 'react';
import PropTypes from 'prop-types';

import { Field, reduxForm, Form } from 'redux-form';
import TextField from '../common/TextField';
import SelectField from '../common/SelectField';

const required = (value) => (value && value !== '' ? undefined : 'Required');

function CouponForm(props) {
  const { handleSubmit, pristine, submitting, closeDialog, initialValues, allMemberships } = props;

  // Get Initial Data
  const membershipsArray = [];
  // eslint-disable-next-line
  allMemberships.map((item) => {
    // eslint-disable-next-line
    membershipsArray.push(
      // eslint-disable-next-line
      <option key={item['_id']} value={item['_id']}>
        {item.name}
      </option>
    );
  });

  return (
    <Form onSubmit={handleSubmit} className="customForm addMembership coupons">
      <div>
        <Field
          name="code"
          component={TextField}
          label="Coupon Code"
          placeholder="Enter Coupon Code"
          validate={required}
        />
      </div>
      <div>
        <Field
          name="useLimit"
          component={TextField}
          label="Usage Limit"
          placeholder="Enter usage limit of coupon"
          type="number"
          validate={required}
        />
      </div>
      {/* eslint-disable-next-line */}
      <div className="meditation-block">
        <Field
          name="membershipId"
          component={SelectField}
          label="Membership"
          placeholder="Select membership"
        >
          {membershipsArray.map((item) => item)}
        </Field>
      </div>

      <div className="text-right">
        <button type="button" onClick={closeDialog} className="submit-button cancel-button">
          Cancel
        </button>
        <button type="submit" className="submit-button" disabled={pristine || submitting}>
          {initialValues ? 'Update' : 'Add'}
        </button>
      </div>
    </Form>
  );
}

CouponForm.propTypes = {
  initialValues: PropTypes.shape({}),
  closeDialog: PropTypes.func,
  handleSubmit: PropTypes.func,
  pristine: PropTypes.bool,
  submitting: PropTypes.bool,
  allMemberships: PropTypes.arrayOf(PropTypes.shape({})),
};

CouponForm.defaultProps = {
  initialValues: undefined,
  closeDialog: undefined,
  handleSubmit: undefined,
  pristine: undefined,
  submitting: undefined,
  allMemberships: undefined,
};

export default reduxForm({
  form: 'addMembership',
})(CouponForm);
