import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import Paper from '@material-ui/core/Paper';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Pagination from '@material-ui/lab/Pagination';
import { IoIosNotifications } from 'react-icons/io';

import MembersForm from './MembersForm';
import MembersNotificationForm from './MembersNotificationForm';

import icoEdit from '../../assets/images/icons/ico-edit.svg';
import icoTrash from '../../assets/images/icons/ico-trash.svg';
import icoPremium from '../../assets/images/goldbounce.png';
import icoAdd from '../../assets/images/icons/ico-add.svg';
import ava from '../../assets/images/ava.jpg';

import {
  MEMBERS_ADD_REQUEST,
  MEMBERS_UPDATE_REQUEST,
  MEMBERS_DELETE_REQUEST,
  GET_ALL_MEMBERS_REQUEST,
  GET_ALL_MEMBERSHIPS_REQUEST,
  NOTIFICATION_ADD_FOR_MEMBER_REQUEST,
} from '../../redux/actions/actionTypes';
import SearchMemberForm from './SearchMemberForm';

function Members(props) {
  const {
    addMembers,
    updateMembers,
    deleteMembers,
    getAllMemberships,
    getAllMembers,
    allMembers,
    allMemberships,
    addNotificationForMember,
  } = props;
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [users, setUsers] = useState([]);
  const [searchValues, setSearchValues] = useState(null);
  const [isNotificationDialogOpen, setIsNotificationDialogOpen] = useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [membersData, setMembersData] = useState();

  // Pagination
  const [activePage, setActivePage] = useState(1);
  const [pagesCount, setPagesCount] = useState(1);
  const [itemsPerPage] = useState(10);

  useEffect(() => {
    // eslint-disable-next-line
    if (
      allMembers &&
      allMembers.list &&
      Array.isArray(allMembers.list) &&
      allMembers.list.length > 0
    ) {
      setUsers(allMembers.list);
      setPagesCount(allMembers.totalPages);
    }
  }, [allMembers, itemsPerPage]);
  useEffect(() => {
    if (activePage > pagesCount) {
      setActivePage(1);
    }
  }, [pagesCount, activePage]);

  useEffect(() => {
    let data = {
      limit: itemsPerPage,
      page: activePage,
    };
    if (searchValues) {
      data = { ...data, ...searchValues };
    }
    getAllMembers(data);
  }, [activePage]);

  useEffect(() => {
    setActivePage(1);
    let data = {
      limit: itemsPerPage,
      page: 1,
    };
    if (searchValues) {
      data = { ...data, ...searchValues };
    }
    getAllMembers(data);
  }, [searchValues]);

  const handlePageChange = (event, value) => {
    setActivePage(value);
  };

  // Get Initial Data
  useEffect(() => {
    getAllMemberships();
  }, [getAllMemberships]);

  useEffect(() => {
    const data = {
      limit: itemsPerPage,
      page: activePage,
    };
    getAllMembers(data);
  }, [getAllMembers]);

  // Add/Update Member
  const openDialog = (data) => {
    // eslint-disable-next-line
    if (data['_id']) {
      setMembersData(data);
    }
    setIsDialogOpen(true);
  };

  const openNotificationDialog = (data) => {
    // eslint-disable-next-line
    if (data['_id']) {
      setMembersData(data);
    }
    setIsNotificationDialogOpen(true);
  };

  const closeDialog = () => {
    setIsDialogOpen(false);
    setMembersData(undefined);
  };

  const closeNotificationDialog = () => {
    setIsNotificationDialogOpen(false);
    setMembersData(undefined);
  };

  const submit = (values) => {
    if (membersData) {
      const updatingData = membersData;
      // eslint-disable-next-line
      updatingData.id = membersData['_id'];
      // eslint-disable-next-line no-return-assign
      Object.keys(values).map((item) => (updatingData[item] = values[item]));
      updateMembers(updatingData);
    } else {
      addMembers(values);
    }
    closeDialog();
  };

  const onSearch = (values) => {
    console.log('[GET_ALL_MEMBERS values', values);
    setSearchValues({ ...values });
    // getAllMembers(values);
  };

  const submitNotification = (values) => {
    const dataToSend = {
      // externalIds: ['5f38caa5c89bdc28ba532ffe'],
      // eslint-disable-next-line
      externalIds: [values['_id']],
      title: values.title,
      body: values.body,
      notificationImage: values.notificationImage,
    };
    addNotificationForMember(dataToSend);
    closeNotificationDialog();
  };

  // Delete Membership
  const openDeleteDialog = (data) => {
    setMembersData(data);
    setIsDeleteDialogOpen(true);
  };

  const closeDeleteDialog = () => {
    setIsDeleteDialogOpen(false);
    setMembersData(undefined);
  };

  const confirmDelete = () => {
    // eslint-disable-next-line
    deleteMembers({ id: membersData['_id'] });
    closeDeleteDialog();
  };

  return (
    <div className="members">
      <div className="page-title">
        <h1>Members</h1>
      </div>

      <SearchMemberForm onSubmit={onSearch} allMemberships={allMemberships} />

      <Paper className="paper">
        <div className="members-head">
          <div className="head-cell cell-name">Name</div>
          <div className="head-cell cell-email">Email</div>
          <div className="head-cell cell-membership">Type</div>
          <div className="head-cell cell-membership">Membership</div>
          <div className="head-cell cell-membership">Expires At</div>
          <div className="head-cell cell-actions">Actions</div>
        </div>

        {/* {allMembers.slice((activePage - 1) * itemsPerPage, activePage * itemsPerPage).map(item => ( */}
        {users.map((item) => (
          // eslint-disable-next-line
          <div className="item-wrapper" key={item['_id']}>
            <div className="head-cell cell-name">
              <div className="avatar">
                <img src={item.image ? item.image : ava} alt="" />
              </div>
              <span>{item.name}</span>
            </div>
            <div className="head-cell cell-email">{item.email}</div>
            <div className="head-cell cell-membership center">
              {item.isPremiumUser ? (
                <img src={icoPremium} alt="" className="premium-marker" />
              ) : (
                <img src={ava} alt="" className="premium-marker" />
              )}
            </div>
            <div className="head-cell cell-membership">
              {item.membershipObj && item.membershipObj.name ? item.membershipObj.name : '-'}
            </div>
            <div className="head-cell cell-membership">
              {item.membershipExpiresAt
                ? moment(item.membershipExpiresAt).format('Do MMM yyyy')
                : '-'}
            </div>
            <div className="head-cell cell-actions">
              <div className="action-icons">
                <div className="action-icon" role="presentation" onClick={() => openDialog(item)}>
                  <img src={icoEdit} alt="" className="edit-ico" />
                </div>
                <div
                  className="action-icon"
                  role="presentation"
                  onClick={() => openDeleteDialog(item)}
                >
                  <img src={icoTrash} alt="" className="edit-ico" />
                </div>
                <div
                  className="action-icon"
                  role="presentation"
                  onClick={() => openNotificationDialog(item)}
                >
                  <IoIosNotifications size={23} color="#00264e" />
                </div>
              </div>
            </div>
          </div>
        ))}

        <div className="add-ico" role="presentation" onClick={openDialog}>
          <img src={icoAdd} alt="" />
        </div>
      </Paper>

      {pagesCount !== 1 ? (
        <div className="pagination-wrapper">
          <Pagination count={pagesCount} page={activePage} onChange={handlePageChange} />
        </div>
      ) : null}

      <Dialog open={isDialogOpen} onClose={closeDialog} className="memberships-dialog">
        <DialogTitle>{membersData ? 'Update Member' : 'Add Member'}</DialogTitle>
        <DialogContent>
          <MembersForm
            initialValues={membersData}
            allMemberships={allMemberships}
            closeDialog={closeDialog}
            onSubmit={submit}
          />
        </DialogContent>
      </Dialog>

      <Dialog
        open={isNotificationDialogOpen}
        onClose={closeNotificationDialog}
        className="memberships-dialog"
      >
        <DialogTitle>
          {membersData ? 'Send Notification To User' : 'Send Notification To User'}
        </DialogTitle>
        <DialogContent>
          <MembersNotificationForm
            initialValues={membersData}
            closeDialog={closeNotificationDialog}
            onSubmit={submitNotification}
          />
        </DialogContent>
      </Dialog>

      <Dialog open={isDeleteDialogOpen} onClose={closeDeleteDialog} className="memberships-dialog">
        <DialogTitle>Delete Member</DialogTitle>
        <DialogContent>
          <p>Are you sure you want to delete this Member?</p>
          <div className="text-center customForm">
            <button
              type="button"
              onClick={closeDeleteDialog}
              className="submit-button cancel-button"
            >
              No
            </button>
            <button type="button" className="submit-button" onClick={confirmDelete}>
              Yes
            </button>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}

Members.propTypes = {
  addMembers: PropTypes.func,
  addNotificationForMember: PropTypes.func,
  updateMembers: PropTypes.func,
  deleteMembers: PropTypes.func,
  getAllMemberships: PropTypes.func,
  allMemberships: PropTypes.arrayOf(PropTypes.shape({})),
  getAllMembers: PropTypes.func,
  allMembers: PropTypes.arrayOf(PropTypes.shape({})),
};

Members.defaultProps = {
  addMembers: undefined,
  addNotificationForMember: undefined,
  updateMembers: undefined,
  deleteMembers: undefined,
  getAllMemberships: undefined,
  allMemberships: undefined,
  getAllMembers: undefined,
  allMembers: undefined,
};

const mapStateToProps = (state) => ({
  loading: state.loading,
  allMemberships: state.allMemberships,
  allMembers: state.allMembers,
});

const actionsStateToProps = {
  addMembers: (data) => ({ type: MEMBERS_ADD_REQUEST, data }),
  addNotificationForMember: (data) => ({ type: NOTIFICATION_ADD_FOR_MEMBER_REQUEST, data }),
  updateMembers: (data) => ({ type: MEMBERS_UPDATE_REQUEST, data }),
  deleteMembers: (id) => ({ type: MEMBERS_DELETE_REQUEST, id }),
  getAllMembers: (data) => ({ type: GET_ALL_MEMBERS_REQUEST, data }),
  getAllMemberships: () => ({ type: GET_ALL_MEMBERSHIPS_REQUEST }),
};

export default connect(mapStateToProps, actionsStateToProps)(Members);
