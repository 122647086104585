import React from 'react';
import { Field, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';

import Grid from '@material-ui/core/Grid';
import TextField from '../common/TextField';

const required = (value) => (value && value !== '' ? undefined : 'Required');

function RegistrationForm(props) {
  const { handleSubmit, pristine, submitting } = props;

  return (
    <form onSubmit={handleSubmit} className="customForm registration-form">
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Field
            name="Name"
            component={TextField}
            label="Name"
            placeholder="Enter your Name"
            validate={required}
          />
        </Grid>
        <Grid item xs={6}>
          <Field
            name="Surname"
            component={TextField}
            label="Surname"
            placeholder="Enter your Surname"
            validate={required}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Field
            name="Login"
            component={TextField}
            label="Login"
            placeholder="Enter your Login"
            validate={required}
          />
        </Grid>
        <Grid item xs={12}>
          <Field
            name="Password"
            component={TextField}
            label="Password"
            placeholder="Enter your Password"
            type="password"
            validate={required}
          />
        </Grid>
        <Grid item xs={12}>
          <Field
            name="Password"
            component={TextField}
            label=""
            placeholder="Re-enter your Password"
            type="password"
            validate={required}
          />
        </Grid>
      </Grid>
      <button type="submit" className="submit-button" disabled={pristine || submitting}>
        Register
      </button>
    </form>
  );
}

RegistrationForm.propTypes = {
  handleSubmit: PropTypes.func,
  pristine: PropTypes.bool,
  submitting: PropTypes.bool,
  classes: PropTypes.shape({}),
};

RegistrationForm.defaultProps = {
  handleSubmit: undefined,
  pristine: undefined,
  submitting: undefined,
  classes: undefined,
};

export default reduxForm({
  form: 'RegistrationForm',
})(RegistrationForm);
