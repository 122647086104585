import { GET_ALL_PROGRAMS_SUCCESS } from '../actions/actionTypes';

export default function allPrograms(state = [], action) {
  switch (action.type) {
    case GET_ALL_PROGRAMS_SUCCESS:
      return action.payload;
    default:
      return state;
  }
}
