import React from 'react';
import PropTypes from 'prop-types';

import { Field, reduxForm } from 'redux-form';
import TextField from '../common/TextField';
import SelectField from '../common/SelectField';

const required = (value) => (value && value !== '' ? undefined : 'Required');

function MembershipForm(props) {
  const { handleSubmit, pristine, submitting, closeDialog, initialValues, allMemberships } = props;

  // Get Initial Data
  const membershipsArray = [];
  allMemberships.map((item) => {
    membershipsArray.push(
      // eslint-disable-next-line no-underscore-dangle
      <option key={item._id} value={item._id}>
        {item.name}
      </option>
    );
    return null;
  });

  return (
    <form onSubmit={handleSubmit} className="customForm addMembership">
      <div>
        <Field
          name="name"
          component={TextField}
          label="Name"
          placeholder="Enter Member's name"
          validate={required}
        />
      </div>
      <div>
        <Field
          name="email"
          component={TextField}
          label="Email"
          placeholder="Enter Member's email"
          validate={required}
          disabled={!!initialValues}
        />
      </div>
      {!initialValues ? (
        <div>
          <Field
            name="password"
            component={TextField}
            label="Password"
            type="password"
            placeholder="Enter password"
            validate={required}
          />
        </div>
      ) : null}

      <div>
        <Field
          name="membership"
          component={SelectField}
          label="Membership"
          placeholder="Select Membership"
          validate={required}
        >
          {membershipsArray.map((item) => item)}
        </Field>
      </div>

      <div className="text-right">
        <button type="button" onClick={closeDialog} className="submit-button cancel-button">
          Cancel
        </button>
        <button type="submit" className="submit-button" disabled={pristine || submitting}>
          {initialValues ? 'Update' : 'Add'}
        </button>
      </div>
    </form>
  );
}

MembershipForm.propTypes = {
  initialValues: PropTypes.shape({}),
  closeDialog: PropTypes.func,
  handleSubmit: PropTypes.func,
  pristine: PropTypes.bool,
  submitting: PropTypes.bool,
  allMemberships: PropTypes.arrayOf(PropTypes.shape({})),
};

MembershipForm.defaultProps = {
  initialValues: undefined,
  closeDialog: undefined,
  handleSubmit: undefined,
  pristine: undefined,
  submitting: undefined,
  allMemberships: undefined,
};

export default reduxForm({
  form: 'addMembership',
})(MembershipForm);
