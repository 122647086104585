import React from 'react';
import PropTypes from 'prop-types';
import InputLabel from '@material-ui/core/InputLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import { Field, reduxForm, Form } from 'redux-form';
import MultiselectField from '../common/MultiselectField';
import TextField from '../common/TextField';
import FileField from '../common/FileField';
import { ASSETS_BASE_URL } from '../../redux/env';

const required = (value) => (value && value !== '' ? undefined : 'Required');
// const fileSelect = value => (value ? undefined : 'You forgot to choose image');

function NotificationForm(props) {
  const {
    handleSubmit,
    pristine,
    submitting,
    closeDialog,
    initialValues,
    allMembersForNotification,
    notificationType,
    setSendingMode,
    sendingMode,
  } = props;

  // Get Initial Data

  return (
    <Form
      onSubmit={handleSubmit}
      className="customForm addMembership coupons customNotificationWrapper"
    >
      {initialValues && (notificationType === 'MEDITATION' || notificationType === 'GROUP') ? (
        <div>
          <div>
            <InputLabel>
              Selected {notificationType === 'MEDITATION' ? 'Meditation' : 'Group'}
            </InputLabel>
            <div className="notificationRow">
              <div className="notificationLeft">
                {notificationType === 'GROUP' ? (
                  <img src={`${ASSETS_BASE_URL}/${initialValues.image}`} alt="" className="icon" />
                ) : (
                  <img
                    src={`${ASSETS_BASE_URL}/${initialValues.thumbnail}`}
                    alt=""
                    className="icon"
                  />
                )}
              </div>
              <div className="notificationRight">
                <InputLabel>{initialValues.name}</InputLabel>
              </div>
            </div>
          </div>
        </div>
      ) : initialValues && notificationType === 'ENTITY' ? (
        <div>
          <div>
            <InputLabel>Selected Entity</InputLabel>
            <div className="notificationRow">
              <div className="notificationLeft">
                <img
                  src={`${ASSETS_BASE_URL}/${initialValues.profileImage}`}
                  alt=""
                  className="icon"
                />
              </div>
              <div className="notificationRight">
                <InputLabel>{`${initialValues.firstName} ${initialValues.lastName}`}</InputLabel>
              </div>
            </div>
          </div>
        </div>
      ) : null}
      <div className="modeSelection">
        <RadioGroup
          aria-label="Sending Mode"
          name="uploadType"
          value={sendingMode}
          className="radioBtns"
        >
          {/* <FormControlLabel value={1} control={<Radio />} label="Send to all users" onClick={() => setSendingMode(1)} /> */}
          <FormControlLabel
            value={2}
            control={<Radio />}
            className="inactiveRadioBtn"
            label="Send to selected users"
            onClick={() => setSendingMode(2)}
          />
        </RadioGroup>
      </div>
      {sendingMode === 2 ? (
        <div>
          <Field
            name="userIds"
            component={MultiselectField}
            label="Select Users"
            placeholder="Select Users"
            dataArray={allMembersForNotification}
            validate={required}
          >
            {allMembersForNotification.map((item) => item)}
          </Field>
        </div>
      ) : null}
      <div>
        <Field
          name="title"
          component={TextField}
          label="Notification Title"
          placeholder="Enter Notification Title"
          validate={required}
        />
      </div>
      <div>
        <Field
          name="body"
          component={TextField}
          label="Notification Body"
          placeholder="Enter Notification Body"
          validate={required}
        />
      </div>
      <div>
        <Field
          name="notificationImage"
          label="Select Notification Image(Optional)"
          placeholder="Select Notification Image(Optional)"
          component={FileField}
          validate={undefined}
        />
      </div>

      <div className="text-right">
        <button type="button" onClick={closeDialog} className="submit-button cancel-button">
          Cancel
        </button>
        <button type="submit" className="submit-button" disabled={pristine || submitting}>
          {initialValues ? 'Update' : 'Add'}
        </button>
      </div>
    </Form>
  );
}

NotificationForm.propTypes = {
  initialValues: PropTypes.shape({}),
  closeDialog: PropTypes.func,
  handleSubmit: PropTypes.func,
  pristine: PropTypes.bool,
  submitting: PropTypes.bool,
  allMembersForNotification: PropTypes.arrayOf(PropTypes.shape({})),
  notificationType: PropTypes.string,
  setSendingMode: PropTypes.func,
  sendingMode: PropTypes.number,
};

NotificationForm.defaultProps = {
  initialValues: undefined,
  closeDialog: undefined,
  handleSubmit: undefined,
  pristine: undefined,
  submitting: undefined,
  allMembersForNotification: undefined,
  notificationType: undefined,
  setSendingMode: undefined,
  sendingMode: undefined,
};

export default reduxForm({
  form: 'addMembership',
})(NotificationForm);
