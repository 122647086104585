import { call, put } from 'redux-saga/effects';
import axios from './axiosHandler';
import { API_HOST } from '../env';
import sagaLoading from './sagaLoading';
import sagaGetAllSeries from './sagaGetAllSeries';
import sagaGetAllPrograms from './sagaGetAllPrograms';
import { MESSAGE_ERROR, MESSAGE_SUCCESS } from '../actions/actionTypes';

export default function* sagaUpdateSeries({ data }) {
  const updateData = new FormData();
  // eslint-disable-next-line
  updateData.set('id', data['_id']);
  updateData.set('name', data.name);
  updateData.set('tags', data.tags.join());
  updateData.set('memberships', data.memberships);
  updateData.set('description', data.description);
  updateData.set('type', data.type);
  if (data.thumbnail) {
    updateData.append('thumbnail', data.thumbnail);
  }

  const serType = { type: data.type };
  try {
    yield call(sagaLoading, true);
    yield call(() =>
      axios({
        url: `${API_HOST}content/updateSeries`,
        method: 'post',
        data: updateData,
      }).then((response) => response.data)
    );
    yield call(sagaGetAllSeries, { serType });
    yield call(sagaGetAllPrograms, { serType });
    yield put({ type: MESSAGE_SUCCESS, payload: 'Updated successfully' });
    yield call(sagaLoading, false);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
    yield call(sagaLoading, false);
    yield put({
      type: MESSAGE_ERROR,
      payload: error.message ? error.message : 'There is something wrong, please, try again.',
    });
  }
}
