import React, { useEffect, useState } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Field, reduxForm, Form } from 'redux-form';
import PropTypes from 'prop-types';
import InputLabel from '@material-ui/core/InputLabel';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';

import Chip from '@material-ui/core/Chip';
import { IoIosNotifications } from 'react-icons/io';
import TextField from '../common/TextField';
import MultiselectField from '../common/MultiselectField';
import FileField from '../common/FileField';

import dropdownIco from '../../assets/images/icons/ico-dropdown.svg';
import openInTab from '../../assets/images/icons/ico-open-tab.svg';
import editIco from '../../assets/images/icons/ico-edit.svg';
import { getDate, getMembershipData, getGroupData, toHHMMSS } from '../../utils/helpers';
import SelectField from '../common/SelectField';

import {
  GET_ALL_PARENTS_REQUEST,
  GET_ALL_MEMBERS_FOR_NOTIFICATION_REQUEST,
  CREATE_CUSTOM_NOTIFICATION,
} from '../../redux/actions/actionTypes';
import { ASSETS_BASE_URL } from '../../redux/env';
import CustomNotificationForm from '../CustomNotification/CustomNotificationForm';

const required = (value) => (value && value !== '' ? undefined : 'Required');
const fileSelect = (value) => (value ? undefined : 'You forgot to choose files');

function Item(props) {
  const {
    handleSubmit,
    pristine,
    submitting,
    allMemberships,
    allGroups,
    allMeditations,
    allSeries,
    allPrograms,
    form,
    initialValues,
    // eslint-disable-next-line no-underscore-dangle
    initialValues: {
      // eslint-disable-next-line no-underscore-dangle
      id = initialValues._id,
      name,
      createdAt,
      memberships,
      groups,
      thumbnail,
      type,
      animation,
    },
    isActive,
    setActive,
    deleteItem,
    getAllParents,
    allParents,
    getAllMembersForNotification,
    allMembersForNotification,
    createCustomNotification,
  } = props;

  const [isEditName, setIsEditName] = useState(false);
  const [isEditMemberships, setIsEditMemberships] = useState(false);
  const [isEditGroups, setIsEditGroups] = useState(false);
  const [isEditThumb, setIsEditThumb] = useState(false);
  const [isEditAnimation, setIsEditAnimation] = useState(false);

  // Meditation Notification
  const [isDialogForMultipleUsersOpen, setIsDialogForMultipleUsersOpen] = useState(false);
  const [notificationData, setNotificationData] = useState();
  const [sendingMode, setSendingMode] = useState(2);

  // Get Initial Data
  const membershipsArray = [];
  allMemberships.map((item) => {
    // eslint-disable-next-line
    membershipsArray.push({ name: item.name, id: item['_id'] });
    return null;
  });

  // Get Initial Data
  const groupsArray = [];
  allGroups.map((item) => {
    // eslint-disable-next-line
    groupsArray.push({ name: item.name, id: item['_id'] });
    return null;
  });

  const seriesArray = [];
  allSeries
    .filter((item) => !item.seriesId)
    .map((item) =>
      seriesArray.push(
        // eslint-disable-next-line
        <option key={item['_id']} value={item['_id']}>
          {item.name}
        </option>
      )
    );

  const programArray = [];
  allPrograms
    .filter((item) => !item.programId)
    .map((item) =>
      programArray.push(
        // eslint-disable-next-line
        <option key={item['_id']} value={item['_id']}>
          {item.name}
        </option>
      )
    );

  const meditationArray = [];
  allMeditations
    .filter((item) => !item.meditationId)
    .map((item) =>
      meditationArray.push(
        // eslint-disable-next-line
        <option key={item['_id']} value={item['_id']}>
          {item.name}
        </option>
      )
    );

  // Get Initial Parents Data
  useEffect(() => {
    if (isActive) {
      getAllParents(name);
    }
  }, [isActive]);

  const parentsArray = [];
  allParents.map((item) =>
    parentsArray.push(
      // eslint-disable-next-line
      <option key={item['_id']} value={item['_id']}>
        {/* eslint-disable-next-line */}
        {item.name} - {toHHMMSS(item.duration)} - {item['_id']}
      </option>
    )
  );

  // handle Edit ico for Fields
  const handleEditName = () => {
    setIsEditName(!isEditName);
  };

  const handleEditMemberships = () => {
    setIsEditMemberships(!isEditMemberships);
  };

  const handleEditGroups = () => {
    setIsEditGroups(!isEditGroups);
  };

  const handleEditThumb = () => {
    setIsEditThumb(!isEditThumb);
  };

  const handleEditAnimation = () => {
    setIsEditAnimation(!isEditAnimation);
  };

  // Delete item
  const handleDelete = (itemId, itemType) => {
    deleteItem(itemId, itemType);
  };

  // Open-close item
  const toggleItem = () => {
    setActive(id);
    setIsEditName(false);
    setIsEditMemberships(false);
    setIsEditGroups(false);
    setIsEditThumb(false);
    setIsEditAnimation(false);
  };

  useEffect(() => {
    const data = {
      name: null,
    };
    getAllMembersForNotification(data);
  }, [getAllMembersForNotification]);

  const openDialogForMultipleUsers = (data) => {
    console.log('[NOTIF_DEBUG allMembersForNotification]', allMembersForNotification);
    // if (data.title) {
    setNotificationData(data);
    // }
    setIsDialogForMultipleUsersOpen(true);
  };

  const closeDialogForMultipleUsers = () => {
    setIsDialogForMultipleUsersOpen(false);
    setNotificationData(undefined);
  };

  const submitNotificationForMultipleUsers = (values) => {
    console.log('[NOTIFICATION_IMAGE values]', values);
    let userIds = null;
    if (values.userIds && values.userIds.split(',').length > 0) {
      userIds = values.userIds.split(',');
    }
    const dataToSend = {
      // externalIds: userIds,
      // externalIds: ["5f38caa5c89bdc28ba532ffe"],
      title: values.title,
      body: values.body,
      notificationImage: values.notificationImage,
      notificationType: 'MEDITATION',
      notificationPayload: { _id: values._id },
    };
    if (sendingMode === 2) {
      dataToSend.externalIds = userIds;
    }
    console.log('[NOTIFICATION_IMAGE dataToSend]', dataToSend);
    createCustomNotification(dataToSend);
    closeDialogForMultipleUsers();
  };

  return (
    <div className="item-wrapper">
      <Form onSubmit={handleSubmit} form={form} className="customForm itemUpdate">
        <div className="first-line">
          <div className="item-cell item-name">
            {isEditName && isActive ? (
              <Field
                name="name"
                component={TextField}
                placeholder="Enter name"
                validate={required}
                disabled={!isActive}
                fullWidth
              />
            ) : (
              <>
                <span>{name}</span>{' '}
                {isActive ? (
                  <div className="edit-ico" role="presentation" onClick={handleEditName}>
                    <img src={editIco} alt="" />
                  </div>
                ) : null}
                <br />
                <span style={{ fontSize: 12, fontWeight: 'normal' }}>{id}</span>
              </>
            )}
          </div>
          <div className="item-cell item-date">{getDate(createdAt)}</div>
          <div className="item-cell item-delete-flex">
            {isActive ? (
              <button
                type="button"
                className="delete-button"
                onClick={() => handleDelete(id, type)}
              >
                Delete
              </button>
            ) : (
              ''
            )}
            {/* eslint-disable-next-line */}
            {!isActive && type === 'singleAudio' ? (
              // eslint-disable-next-line no-underscore-dangle
              <a target="_blank" href={`/audios/${initialValues._id}`} rel="noopener noreferrer">
                <img src={openInTab} alt="" className="tab-icon" role="presentation" />
              </a>
            ) : null}
            {!isActive && type === 'singleAudio' ? (
              // eslint-disable-next-line no-underscore-dangle
              <a
                className="action-icon-notif"
                role="presentation"
                onClick={() => openDialogForMultipleUsers(initialValues)}
              >
                <IoIosNotifications size={23} color="#00264e" />
              </a>
            ) : null}
            <img
              src={dropdownIco}
              alt=""
              className={isActive ? 'toggle-item active' : 'toggle-item'}
              role="presentation"
              onClick={toggleItem}
            />
          </div>
        </div>
        <div className={isActive ? 'item-details active' : 'item-details'}>
          <div className="params-wrapper">
            <div className="params-left">
              <div className="memberships-block">
                <div className="param-title">Memberships</div>

                {isEditMemberships && isActive ? (
                  <Field
                    name="memberships"
                    component={MultiselectField}
                    label="Memberships"
                    placeholder="Select Membership"
                    dataArray={allMemberships}
                    validate={required}
                  >
                    {membershipsArray.map((item) => item)}
                  </Field>
                ) : (
                  <>
                    {memberships.map((item) => (
                      <Chip
                        className="membership-item"
                        label={getMembershipData(allMemberships, item).name}
                        variant="outlined"
                        key={item}
                      />
                    ))}
                    {isActive ? (
                      <div className="edit-ico" role="presentation" onClick={handleEditMemberships}>
                        <img src={editIco} alt="" />
                      </div>
                    ) : null}
                  </>
                )}
              </div>
              <div className="memberships-block">
                <div className="param-title">Groups</div>

                {isEditGroups && isActive ? (
                  <Field
                    name="groups"
                    component={MultiselectField}
                    label="Groups"
                    placeholder="Select Groups"
                    dataArray={allGroups}
                    validate={required}
                  >
                    {groupsArray.map((item) => item)}
                  </Field>
                ) : (
                  <>
                    {groups.map((item) => (
                      <Chip
                        className="membership-item"
                        label={getGroupData(allGroups, item).name}
                        variant="outlined"
                        key={item}
                      />
                    ))}
                    {isActive ? (
                      <div className="edit-ico" role="presentation" onClick={handleEditGroups}>
                        <img src={editIco} alt="" />
                      </div>
                    ) : null}
                  </>
                )}
              </div>
              {/* eslint-disable-next-line */}
              <div className="memberships-block">
                <div className="param-title">Is Featured</div>
                <Field
                  name="isFeatured"
                  component={SelectField}
                  label="Is Featured"
                  placeholder="Select Is Featured"
                >
                  <option value="">Select</option>
                  <option value>Yes</option>
                  <option value={false}>No</option>
                </Field>
              </div>
              <div className="memberships-block">
                <div className="param-title">Is Premium</div>
                <Field
                  name="isPremium"
                  component={SelectField}
                  label="Is Premium"
                  placeholder="Select Is Premium"
                >
                  <option value="">Select</option>
                  <option value>Yes</option>
                  <option value={false}>No</option>
                </Field>
              </div>
              {/* eslint-disable-next-line */}
              <div className="memberships-block">
                <div className="param-title">Parent Meditation</div>
                <Field name="parentId" component={SelectField} label="Is Featured">
                  <option value="">Select Parent</option>
                  {parentsArray.map((item) => item)}
                </Field>
              </div>
              {/* eslint-disable-next-line */}
              {/* {type.indexOf('series') === -1 && type.indexOf('program') === -1 ? (
                <div className="memberships-block">
                  <div className="param-title">Series</div>
                  <Field name="seriesId" component={SelectField} label="Series" placeholder="Select series">
                    {seriesArray.map(item => item)}
                  </Field>
                </div>
              ) : null} */}
              {/* eslint-disable-next-line */}
              {/* {type.indexOf('series') === -1 && type.indexOf('program') === -1 ? (
                <div className="memberships-block">
                  <div className="param-title">Program</div>
                  <Field name="programId" component={SelectField} label="Program" placeholder="Select program">
                    {programArray.map(item => item)}
                  </Field>
                </div>
              ) : null} */}
              {/* <div className="tags-block">
                <Field
                  name="tags"
                  component={TagsField}
                  label="Tags"
                  disabled={!isEditTags}
                  placeholder="Enter #tags"
                  validate={required}
                />
                {isActive && !isEditTags ? (
                  <div className="edit-ico" role="presentation" onClick={handleEditTags}>
                    <img src={editIco} alt="" />
                  </div>
                ) : null}
              </div> */}
              {/* eslint-disable-next-line */}
              {/* {type.indexOf('series') === -1 && type.indexOf('program') === -1 ? (
                <div className="meditation-block">
                  <Field name="meditationId" component={SelectField} label="Meditation" placeholder="Select meditation">
                    {meditationArray.map(item => item)}
                  </Field>
                </div>
              ) : null} */}
              <div className="animation-block">
                <div className="fileInput">
                  <InputLabel>Animation</InputLabel>
                  {/* eslint-disable-next-line */}
                  {isActive && isEditAnimation ? (
                    <Field name="animation" component={FileField} label="" validate={fileSelect} />
                  ) : animation ? (
                    <div className="file-wrapper">
                      {isActive && !isEditAnimation ? (
                        <div className="edit-ico" role="presentation" onClick={handleEditAnimation}>
                          <img src={editIco} alt="" />
                        </div>
                      ) : null}
                      <img src={`${ASSETS_BASE_URL}/${animation}`} className="thumb" alt="" />
                    </div>
                  ) : (
                    <Field name="animation" component={FileField} label="" validate={fileSelect} />
                  )}
                </div>
              </div>
            </div>
            <div className="params-right params-right-flex-start">
              <div className="file-wrapper">
                {isActive && !isEditThumb ? (
                  <div className="edit-ico" role="presentation" onClick={handleEditThumb}>
                    <img src={editIco} alt="" />
                  </div>
                ) : null}
                <img src={`${ASSETS_BASE_URL}/${thumbnail}`} className="thumb" alt="" />
              </div>
            </div>
          </div>

          {isActive && isEditThumb ? (
            <div className="params-wrapper">
              <div className="params-left" />
              <div className="params-right">
                <Field name="thumbnail" component={FileField} label="" validate={fileSelect} />
              </div>
            </div>
          ) : null}

          {/* <div className="item-cell item-name">
            <div className="param-title">Artist</div>
            {isEditArtist && isActive ? (
              <Field
                name="artist"
                component={TextField}
                placeholder="Enter artist name"
                validate={required}
                disabled={!isActive}
                fullWidth
              />
            ) : (
              <>
                <span>{artist}</span>{' '}
                {isActive ? (
                  <div className="edit-ico" role="presentation" onClick={handleEditArtist}>
                    <img src={editIco} alt="" />
                  </div>
                ) : null}
              </>
            )}
          </div> */}

          {/* <div className="item-cell item-name">
            <div className="param-title">Composer</div>
            {isEditComposer && isActive ? (
              <Field
                name="composer"
                component={TextField}
                placeholder="Enter composer name"
                validate={required}
                disabled={!isActive}
                fullWidth
              />
            ) : (
              <>
                <span>{composer}</span>{' '}
                {isActive ? (
                  <div className="edit-ico" role="presentation" onClick={handleEditComposer}>
                    <img src={editIco} alt="" />
                  </div>
                ) : null}
              </>
            )}
          </div> */}

          <div className="description-block">
            {/* <Field
              name="description"
              component={TextareaField}
              label="Description"
              placeholder="Enter description"
              multiline
              fullWidth
              rowsMax="4"
              maxLength="1200"
              validate={required}
            /> */}
            <div className="update-button">
              <button type="submit" className="submit-button" disabled={pristine || submitting}>
                Update
              </button>
            </div>
          </div>
        </div>
      </Form>
      <Dialog
        open={isDialogForMultipleUsersOpen}
        onClose={closeDialogForMultipleUsers}
        className="notifications-dialog"
      >
        <DialogTitle>Send Notification To Multiple Users</DialogTitle>
        <DialogContent>
          <CustomNotificationForm
            initialValues={initialValues}
            closeDialog={closeDialogForMultipleUsers}
            onSubmit={submitNotificationForMultipleUsers}
            allMembersForNotification={allMembersForNotification}
            getAllMembersForNotification={getAllMembersForNotification}
            notificationType="MEDITATION"
            sendingMode={sendingMode}
            setSendingMode={setSendingMode}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
}

Item.propTypes = {
  handleSubmit: PropTypes.func,
  pristine: PropTypes.bool,
  submitting: PropTypes.bool,
  initialValues: PropTypes.shape({
    id: PropTypes.number,
    _id: PropTypes.number,
    name: PropTypes.string,
    artist: PropTypes.string,
    composer: PropTypes.string,
    type: PropTypes.string,
    createdAt: PropTypes.string,
    memberships: PropTypes.arrayOf(PropTypes.string),
    groups: PropTypes.arrayOf(PropTypes.string),
    thumbnail: PropTypes.any,
    animation: PropTypes.any,
    series: PropTypes.string,
  }),
  isActive: PropTypes.bool,
  setActive: PropTypes.func,
  allMemberships: PropTypes.arrayOf(PropTypes.shape({})),
  allGroups: PropTypes.arrayOf(PropTypes.shape({})),
  allSeries: PropTypes.arrayOf(PropTypes.shape({})),
  allPrograms: PropTypes.arrayOf(PropTypes.shape({})),
  allMeditations: PropTypes.arrayOf(PropTypes.shape({})),
  form: PropTypes.string,
  deleteItem: PropTypes.func,
  getAllParents: PropTypes.func,
  allParents: PropTypes.arrayOf(PropTypes.shape({})),
  getAllMembersForNotification: PropTypes.func,
  allMembersForNotification: PropTypes.arrayOf(PropTypes.shape({})),
  createCustomNotification: PropTypes.func,
};

Item.defaultProps = {
  handleSubmit: undefined,
  pristine: undefined,
  submitting: undefined,
  initialValues: undefined,
  isActive: undefined,
  setActive: undefined,
  allMemberships: undefined,
  allGroups: undefined,
  allSeries: undefined,
  allPrograms: undefined,
  allMeditations: undefined,
  form: undefined,
  deleteItem: undefined,
  getAllParents: undefined,
  allParents: undefined,
  getAllMembersForNotification: undefined,
  allMembersForNotification: undefined,
  createCustomNotification: undefined,
};

const mapStateToProps = (state, ownProps) => ({
  form: ownProps.formName,
  allGroups: state.allGroups,
  allParents: state.allParents,
  allMembersForNotification: state.allMembersForNotification,
});

const actionsStateToProps = {
  getAllParents: (name) => ({ type: GET_ALL_PARENTS_REQUEST, name }),
  getAllMembersForNotification: (data) => ({
    type: GET_ALL_MEMBERS_FOR_NOTIFICATION_REQUEST,
    data,
  }),
  createCustomNotification: (data) => ({ type: CREATE_CUSTOM_NOTIFICATION, data }),
};

export default compose(connect(mapStateToProps, actionsStateToProps), reduxForm())(Item);
