import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';

import notifications from './notifications';
import loading from './loading';
import uploadProgress from './uploadProgress';
import currentUser from './login';
import allMemberships from './allMemberships';
import allCoupons from './allCoupons';
import allMeditations from './allMeditations';
import uploadedSeries from './uploadedSeries';
import uploadedSingle from './uploadedSingle';
import allContent from './allContent';
import allAudios from './allAudios';
import allSeries from './allSeries';
import allPrograms from './allPrograms';
import allNotifications from './allNotifications';
import allEntities from './allEntities';
import allGroups from './allGroups';
import audioDetails from './audioDetails';
import allMembers from './allMembers';
import allMembersForNotification from './allMembersForNotification';
import allTags from './allTags';
import allParents from './allParents';
import allGroupSegments from './allGroupSegments';

export const rootReducer = combineReducers({
  loading,
  uploadProgress,
  notifications,
  currentUser,
  allMemberships,
  allCoupons,
  allMeditations,
  uploadedSeries,
  uploadedSingle,
  allContent,
  allAudios,
  allSeries,
  allPrograms,
  allNotifications,
  allEntities,
  allGroups,
  allMembers,
  allMembersForNotification,
  allTags,
  audioDetails,
  allParents,
  allGroupSegments,
  form: formReducer,
});
