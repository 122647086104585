import { call, put } from 'redux-saga/effects';
import axios from './axiosHandler';
import { API_HOST } from '../env';
import sagaLoading from './sagaLoading';
import sagaGetAllContent from './sagaGetAllContent';
import { MESSAGE_ERROR, MESSAGE_SUCCESS } from '../actions/actionTypes';

export default function* sagaUpdateSingle({ data }) {
  const updateData = new FormData();
  // eslint-disable-next-line
  updateData.set('id', data['_id']);
  updateData.set('name', data.name);
  updateData.set('artist', data.artist);
  updateData.set('composer', data.composer);
  updateData.set('tags', data.tags.join());
  updateData.set('memberships', data.memberships);
  updateData.set('isFeatured', data.isFeatured);
  updateData.set('isPremium', data.isPremium);
  updateData.set('groups', data.groups);
  updateData.set('description', data.description);
  updateData.set('parentId', data.parentId);
  updateData.set('type', data.type);
  if (data.seriesId) {
    updateData.set('seriesId', data.seriesId);
  }
  if (data.programId) {
    updateData.set('programId', data.programId);
  }
  if (data.meditationId) {
    updateData.set('meditationId', data.meditationId);
  }
  if (data.thumbnail) {
    updateData.append('thumbnail', data.thumbnail);
  }
  if (data.animation) {
    updateData.append('animation', data.animation);
  }

  try {
    yield call(sagaLoading, true);
    yield call(() =>
      axios({
        url: `${API_HOST}content/updateSingle`,
        method: 'post',
        data: updateData,
      }).then((response) => response.data)
    );
    yield call(sagaGetAllContent);
    yield put({ type: MESSAGE_SUCCESS, payload: 'Updated successfully' });
    yield call(sagaLoading, false);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
    yield call(sagaLoading, false);
    yield put({
      type: MESSAGE_ERROR,
      payload: error.message ? error.message : 'There is something wrong, please, try again.',
    });
  }
}
