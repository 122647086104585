import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Pagination from '@material-ui/lab/Pagination';
import { RiUserVoiceLine } from 'react-icons/ri';

import NotificationForm from './NotificationForm';

import icoAdd from '../../assets/images/icons/ico-add.svg';

import {
  NOTIFICATION_ADD_REQUEST,
  NOTIFICATION_UPDATE_REQUEST,
  NOTIFICATION_DELETE_REQUEST,
  GET_ALL_NOTIFICATIONS_REQUEST,
  GET_ALL_MEMBERS_FOR_NOTIFICATION_REQUEST,
  NOTIFICATION_ADD_FOR_MEMBER_REQUEST,
} from '../../redux/actions/actionTypes';
import NotificationFormForMultipleUsers from './NotificationFormForMultipleUsers';

function Notifications(props) {
  const {
    addNotification,
    updateNotification,
    deleteNotification,
    getAllNotifications,
    allNotifications,
    getAllMembersForNotification,
    allMembersForNotification,
    addNotificationForMember,
  } = props;
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isDialogForMultipleUsersOpen, setIsDialogForMultipleUsersOpen] = useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [notificationData, setNotificationData] = useState();

  // Pagination
  const [activePage, setActivePage] = useState(1);
  const [pagesCount, setPagesCount] = useState(1);
  const [itemsPerPage] = useState(10);

  useEffect(() => {
    if (allNotifications) {
      setPagesCount(Math.ceil(allNotifications.length / itemsPerPage));
    }
  }, [allNotifications, itemsPerPage]);
  useEffect(() => {
    if (activePage > pagesCount) {
      setActivePage(1);
    }
  }, [pagesCount, activePage]);

  const handlePageChange = (event, value) => {
    setActivePage(value);
  };

  // Get Notifications List
  useEffect(() => {
    getAllNotifications();
  }, [getAllNotifications]);

  // Add/Update Notification
  const openDialog = (data) => {
    if (data.title) {
      setNotificationData(data);
    }
    setIsDialogOpen(true);
  };

  useEffect(() => {
    const data = {
      name: null,
    };
    getAllMembersForNotification(data);
  }, [getAllMembersForNotification]);

  const openDialogForMultipleUsers = (data) => {
    if (data.title) {
      setNotificationData(data);
    }
    setIsDialogForMultipleUsersOpen(true);
  };

  const closeDialog = () => {
    setIsDialogOpen(false);
    setNotificationData(undefined);
  };

  const closeDialogForMultipleUsers = () => {
    setIsDialogForMultipleUsersOpen(false);
    setNotificationData(undefined);
  };

  const submit = (values) => {
    console.log('[NOTIFICATION_TYPE values]', values);
    if (notificationData) {
      const updatingData = notificationData;
      // eslint-disable-next-line
      updatingData.id = notificationData['_id'];
      // eslint-disable-next-line no-return-assign
      Object.keys(values).map((item) => (updatingData[item] = values[item]));
      updateNotification(updatingData);
    } else {
      addNotification(values);
    }
    closeDialog();
  };

  const submitNotificationForMultipleUsers = (values) => {
    console.log('[NOTIFICATION_TYPE values]', values);
    const userIds = values.userIds.split(',');
    const dataToSend = {
      externalIds: userIds,
      title: values.title,
      body: values.body,
      notificationImage: values.notificationImage,
      notificationType: values.notificationType,
    };
    console.log('[NOTIFICATION_IMAGE dataToSend]', dataToSend);
    addNotificationForMember(dataToSend);
    closeDialogForMultipleUsers();
  };

  const closeDeleteDialog = () => {
    setIsDeleteDialogOpen(false);
    setNotificationData(undefined);
  };

  const confirmDelete = () => {
    // eslint-disable-next-line
    deleteNotification({ code: notificationData['code'] });
    closeDeleteDialog();
  };

  return (
    <div className="notifications memberships">
      <div className="page-title">
        <h1>Notifications</h1>
      </div>
      <Paper className="paper">
        <Table stickyHeader className="table">
          <TableHead className="table-head">
            <TableRow>
              <TableCell className="th" />
              <TableCell className="th">
                <span>Notification Title</span>
              </TableCell>
              <TableCell className="th">
                <span>Notification Body</span>
              </TableCell>
              {/* <TableCell className="th">
                <span className="text-center">Actions</span>
              </TableCell> */}
            </TableRow>
          </TableHead>
          <TableBody className="table-body">
            {allNotifications
              .slice((activePage - 1) * itemsPerPage, activePage * itemsPerPage)
              .map((row, index) => (
                <TableRow key={row.name}>
                  <TableCell className="cell" style={{ textAlign: 'center' }}>
                    <span># {index + 1}</span>
                  </TableCell>
                  <TableCell className="cell">
                    <span>{row.title}</span>
                  </TableCell>
                  <TableCell className="cell">
                    <span>{row.body}</span>
                  </TableCell>
                  {/* <TableCell className="cell">
                  <div className="action-icons">
                    <div className="action-icon" role="presentation" onClick={() => openDialog(row)}>
                      <img src={icoEdit} alt="" className="edit-ico" />
                    </div>
                    <div className="action-icon" role="presentation" onClick={() => openDeleteDialog(row)}>
                      <img src={icoTrash} alt="" className="edit-ico" />
                    </div>
                  </div>
                </TableCell> */}
                </TableRow>
              ))}
          </TableBody>
        </Table>
        <div
          className="add-ico u-n-icon-cont"
          role="presentation"
          onClick={openDialogForMultipleUsers}
        >
          <RiUserVoiceLine size={23} color="#fff" />
        </div>
        <div className="add-ico" role="presentation" onClick={openDialog}>
          <img src={icoAdd} alt="" />
        </div>
      </Paper>

      {pagesCount !== 1 ? (
        <div className="pagination-wrapper">
          <Pagination count={pagesCount} page={activePage} onChange={handlePageChange} />
        </div>
      ) : null}

      <Dialog open={isDialogOpen} onClose={closeDialog} className="notifications-dialog">
        <DialogTitle>{notificationData ? 'Update Notification' : 'Add Notification'}</DialogTitle>
        <DialogContent>
          <NotificationForm
            initialValues={notificationData}
            closeDialog={closeDialog}
            onSubmit={submit}
          />
        </DialogContent>
      </Dialog>

      <Dialog
        open={isDialogForMultipleUsersOpen}
        onClose={closeDialogForMultipleUsers}
        className="notifications-dialog"
      >
        <DialogTitle>Send Notification To Multiple Users</DialogTitle>
        <DialogContent>
          <NotificationFormForMultipleUsers
            initialValues={notificationData}
            closeDialog={closeDialogForMultipleUsers}
            onSubmit={submitNotificationForMultipleUsers}
            allMembersForNotification={allMembersForNotification}
            getAllMembersForNotification={getAllMembersForNotification}
          />
        </DialogContent>
      </Dialog>

      <Dialog
        open={isDeleteDialogOpen}
        onClose={closeDeleteDialog}
        className="notifications-dialog"
      >
        <DialogTitle>Delete Notification</DialogTitle>
        <DialogContent>
          <p>Are you sure you want to delete this Notification?</p>
          <div className="text-center customForm">
            <button
              type="button"
              onClick={closeDeleteDialog}
              className="submit-button cancel-button"
            >
              No
            </button>
            <button type="button" className="submit-button" onClick={confirmDelete}>
              Yes
            </button>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}

Notifications.propTypes = {
  addNotification: PropTypes.func,
  updateNotification: PropTypes.func,
  deleteNotification: PropTypes.func,
  getAllNotifications: PropTypes.func,
  allNotifications: PropTypes.arrayOf(PropTypes.shape({})),
  getAllMembersForNotification: PropTypes.func,
  allMembersForNotification: PropTypes.arrayOf(PropTypes.shape({})),
  addNotificationForMember: PropTypes.func,
};

Notifications.defaultProps = {
  addNotification: undefined,
  updateNotification: undefined,
  deleteNotification: undefined,
  getAllNotifications: undefined,
  allNotifications: undefined,
  getAllMembersForNotification: undefined,
  allMembersForNotification: undefined,
  addNotificationForMember: undefined,
};

const mapStateToProps = (state) => ({
  loading: state.loading,
  allNotifications: state.allNotifications,
  allMembersForNotification: state.allMembersForNotification,
});

const actionsStateToProps = {
  addNotification: (data) => ({ type: NOTIFICATION_ADD_REQUEST, data }),
  updateNotification: (data) => ({ type: NOTIFICATION_UPDATE_REQUEST, data }),
  deleteNotification: (data) => ({ type: NOTIFICATION_DELETE_REQUEST, data }),
  getAllNotifications: () => ({ type: GET_ALL_NOTIFICATIONS_REQUEST }),
  getAllMembersForNotification: (data) => ({
    type: GET_ALL_MEMBERS_FOR_NOTIFICATION_REQUEST,
    data,
  }),
  addNotificationForMember: (data) => ({ type: NOTIFICATION_ADD_FOR_MEMBER_REQUEST, data }),
};

export default connect(mapStateToProps, actionsStateToProps)(Notifications);
