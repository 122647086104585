import React from 'react';

import { Paper } from '@material-ui/core';
import RegistrationForm from './RegistrationForm';
import history from '../../history';

import logo from '../../assets/images/logo.png';

function Registration() {
  const submit = (values) => {
    console.log('registration of new user: ', values);
    history.push('/login/');
  };

  return (
    <div className="registration-page">
      <div>
        <img src={logo} alt="" className="logo" />
        <Paper className="paper">
          <RegistrationForm onSubmit={submit} />
        </Paper>
      </div>
    </div>
  );
}

export default Registration;
