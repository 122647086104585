import React, { useState } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Field, reduxForm, Form } from 'redux-form';
import PropTypes from 'prop-types';
import SelectField from '../common/SelectField';
import ReverseField from '../common/ReverseField';
import TextField from '../common/TextField';

// import filterIco from '../../assets/images/icons/ico-filter.svg';

function Sorting(props) {
  const { reset, title, data, updateData, form, searchByText } = props;
  const [compForm, setCompForm] = useState();
  const [sortData, setSortData] = useState();
  const [isReversed, setIsReversed] = useState(false);
  const [sortingName, setSortingName] = useState();

  // Sorting function
  const sort = (name) => {
    const rawData = data.map((item) => item);
    if (name) {
      if (name === 'Name') {
        rawData.sort((a, b) => {
          if (a.name > b.name) {
            return 1;
          }
          if (a.name < b.name) {
            return -1;
          }
          return 0;
        });
      } else if (name === 'Date') {
        rawData.sort((a, b) => {
          if (a.createdAt > b.createdAt) {
            return 1;
          }
          if (a.createdAt < b.createdAt) {
            return -1;
          }
          return 0;
        });
      }
    }

    return rawData;
  };

  // reload form on page or tab changing
  if (form !== compForm) {
    setCompForm(form);
    setIsReversed(false);
    setSortingName(undefined);
    reset(`${title}true`);
    reset(`${title}false`);
    updateData(data);
  }

  // filter data after changes at current page
  if (sortData !== data) {
    setSortData(data);

    // update Raw Data with Sorting
    const rawData = sort(sortingName);

    // update Raw Data with Reverse
    if (isReversed) {
      rawData.reverse();
    }

    // Send updated Data to ItemsList
    updateData(rawData);
  }

  // Reverse Array
  const reverseArray = (bool) => {
    setIsReversed(bool);

    // update Raw Data with Sorting
    const rawData = sort(sortingName);

    // update Raw Data with Reverse
    if (bool) {
      rawData.reverse();
    }

    // Send updated Data to ItemsList
    updateData(rawData);
  };

  // Sort Array
  const sortArray = (e) => {
    setSortingName(e.target.value);

    // update Raw Data with Sorting
    const rawData = sort(e.target.value);

    // update Raw Data with Reverse
    if (isReversed) {
      rawData.reverse();
    }

    // Send updated Data to ItemsList
    updateData(rawData);
  };

  return (
    <Form onSubmit={() => {}} form={form} className="customForm sorting">
      <div className="sorting-title">{title}</div>
      <Field
        name="name"
        onChange={searchByText}
        component={TextField}
        label="Name"
        placeholder="Enter name"
      />
      <div className="sorting-el">
        <Field name="reverse" component={ReverseField} label="" reverse={reverseArray} />

        <Field
          name="sort"
          component={SelectField}
          label=""
          onChange={sortArray}
          placeholder="Sort by..."
        >
          <option value="Name">Name</option>
          <option value="Date">Date</option>
        </Field>
      </div>

      {/* <div className="sorting-el">
        <img src={filterIco} className="sorting-ico" alt="" />
        <Field name="filter" component={SelectField} label="" placeholder="Filter by...">
          <option value="name">Name</option>
          <option value="date">Date</option>
        </Field>
      </div> */}
    </Form>
  );
}

Sorting.propTypes = {
  title: PropTypes.string,
  data: PropTypes.arrayOf(PropTypes.shape({})),
  updateData: PropTypes.func,
  reset: PropTypes.func,
  form: PropTypes.string,
  searchByText: PropTypes.func,
};

Sorting.defaultProps = {
  title: undefined,
  data: undefined,
  updateData: undefined,
  reset: undefined,
  form: undefined,
  searchByText: undefined,
};

const mapStateToProps = (state, ownProps) => ({
  form: ownProps.formName,
});

export default compose(connect(mapStateToProps), reduxForm())(Sorting);
