import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Field, reduxForm, Form } from 'redux-form';
import FileField from '../common/FileField';
import TextField from '../common/TextField';
import TagsField from '../common/TagsField';
import editIco from '../../assets/images/icons/ico-edit.svg';

const fileSelect = (value) => (value ? undefined : 'You forgot to choose files');

const required = (value) => (value && value !== '' ? undefined : 'Required');

function MeditationForm(props) {
  const { handleSubmit, pristine, submitting, closeDialog, initialValues } = props;
  const [isEditThumb, setIsEditThumb] = useState(false);

  const handleEditThumb = () => {
    setIsEditThumb(!isEditThumb);
  };

  return (
    <Form onSubmit={handleSubmit} className="customForm addMembership meditation">
      <div>
        <Field
          name="name"
          component={TextField}
          label="Name"
          placeholder="Enter Meditation name"
          validate={required}
          fullWidth
        />
      </div>
      <div>
        <Field
          name="data"
          component={TagsField}
          label="Enter Meditation Data"
          fullWidth
          placeholder="Enter each text and press tab to add multiple text"
          validate={required}
        />
      </div>
      {!initialValues || isEditThumb ? (
        <div>
          <Field
            name="fileLocation"
            component={FileField}
            label="Image"
            validate={fileSelect}
            disabled={false}
          />
        </div>
      ) : null}
      {initialValues && !isEditThumb ? (
        <div className="file-wrapper">
          <div className="edit-ico" role="presentation" onClick={handleEditThumb}>
            <img src={editIco} alt="" />
          </div>
          <img src={`https://public-prod.retreatsounds.com/${initialValues.fileLocation}`} className="icon" alt="" />
        </div>
      ) : null}
      <div className="text-right">
        <button type="button" onClick={closeDialog} className="submit-button cancel-button">
          Cancel
        </button>
        <button type="submit" className="submit-button" disabled={pristine || submitting}>
          {initialValues ? 'Update' : 'Add'}
        </button>
      </div>
    </Form>
  );
}

MeditationForm.propTypes = {
  initialValues: PropTypes.shape({
    fileLocation: PropTypes.any,
  }),
  closeDialog: PropTypes.func,
  handleSubmit: PropTypes.func,
  pristine: PropTypes.bool,
  submitting: PropTypes.bool,
};

MeditationForm.defaultProps = {
  initialValues: undefined,
  closeDialog: undefined,
  handleSubmit: undefined,
  pristine: undefined,
  submitting: undefined,
};

export default reduxForm({
  form: 'addMeditation',
})(MeditationForm);
