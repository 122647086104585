import { UPLOAD_SERIES_SUCCESS } from '../actions/actionTypes';

export default function uploadedSeries(state = {}, action) {
  switch (action.type) {
    case UPLOAD_SERIES_SUCCESS:
      return action.payload;
    default:
      return state;
  }
}
