import { call, put } from 'redux-saga/effects';
import axios from './axiosHandler';
import { API_HOST } from '../env';
import sagaLoading from './sagaLoading';
import { MESSAGE_ERROR, GET_ALL_MEMBERS_SUCCESS } from '../actions/actionTypes';

export default function* sagaGetAllMembers(data) {
  console.log('[GET_ALL_MEMBERS data]', data);
  const { limit, page, email, name, membershipId } = data.data;
  try {
    yield call(sagaLoading, true);
    let url = `${API_HOST}user/list?limit=${limit || 10}&pageNumber=${page || 1}`;
    if (email) {
      url += `&email=${email}`;
    }
    if (name) {
      url += `&name=${name}`;
    }
    if (membershipId) {
      url += `&membershipId=${membershipId}`;
    }
    const obj = {
      url,
      method: 'get',
    };
    const resData = yield call(() =>
      // axios({
      //   url: `${API_HOST}user/getAllUsers`,
      //   method: 'post',
      //   ...data,
      // }).then(response => response.data));
      axios(obj).then((response) => response.data)
    );
    yield put({ type: GET_ALL_MEMBERS_SUCCESS, payload: resData.data });
    yield call(sagaLoading, false);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
    yield call(sagaLoading, false);
    yield put({
      type: MESSAGE_ERROR,
      payload: error.message ? error.message : 'There is something wrong, please, try again.',
    });
  }
}
