import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Pagination from '@material-ui/lab/Pagination';

import { IoIosNotifications } from 'react-icons/io';
import EntityForm from './EntityForm';

import icoAdd from '../../assets/images/icons/ico-add.svg';
import icoEdit from '../../assets/images/icons/ico-edit.svg';
import icoTrash from '../../assets/images/icons/ico-trash.svg';
import CustomNotificationForm from '../CustomNotification/CustomNotificationForm';

import {
  ENTITY_ADD_REQUEST,
  ENTITY_UPDATE_REQUEST,
  ENTITY_DELETE_REQUEST,
  GET_ALL_ENTITYS_REQUEST,
  GET_ALL_MEMBERS_FOR_NOTIFICATION_REQUEST,
  CREATE_CUSTOM_NOTIFICATION,
} from '../../redux/actions/actionTypes';
import { capitalizeFirstLetter } from '../../utils/helpers';
import { ASSETS_BASE_URL } from '../../redux/env';

function Entities(props) {
  const {
    addEntity,
    updateEntity,
    deleteEntity,
    getAllEntities,
    allEntities,
    getAllMembersForNotification,
    allMembersForNotification,
    createCustomNotification,
  } = props;
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [entityData, setEntityData] = useState();

  // Pagination
  const [activePage, setActivePage] = useState(1);
  const [pagesCount, setPagesCount] = useState(1);
  const [itemsPerPage] = useState(10);

  // Entities Notification
  const [isDialogForMultipleUsersOpen, setIsDialogForMultipleUsersOpen] = useState(false);
  const [notificationData, setNotificationData] = useState();
  const [sendingMode, setSendingMode] = useState(2);

  useEffect(() => {
    if (allEntities) {
      setPagesCount(Math.ceil(allEntities.length / itemsPerPage));
    }
  }, [allEntities, itemsPerPage]);
  useEffect(() => {
    if (activePage > pagesCount) {
      setActivePage(1);
    }
  }, [pagesCount, activePage]);

  const handlePageChange = (event, value) => {
    setActivePage(value);
  };

  // Get Entities List
  useEffect(() => {
    getAllEntities();
  }, [getAllEntities]);

  // Add/Update Entity
  const openDialog = (data) => {
    // eslint-disable-next-line no-underscore-dangle
    if (data._id) {
      setEntityData(data);
    }
    setIsDialogOpen(true);
  };

  const closeDialog = () => {
    setIsDialogOpen(false);
    setEntityData(undefined);
  };

  const submit = (values) => {
    if (entityData) {
      const updatingData = entityData;
      // eslint-disable-next-line
      updatingData.id = entityData['_id'];
      // eslint-disable-next-line no-return-assign
      Object.keys(values).map((item) => (updatingData[item] = values[item]));
      updateEntity(updatingData);
    } else {
      addEntity(values);
    }
    closeDialog();
  };

  // Delete Meditation
  const openDeleteDialog = (data) => {
    setEntityData(data);
    setIsDeleteDialogOpen(true);
  };

  const closeDeleteDialog = () => {
    setIsDeleteDialogOpen(false);
    setEntityData(undefined);
  };

  const confirmDelete = () => {
    // eslint-disable-next-line
    deleteEntity({ _id: entityData['_id'] });
    closeDeleteDialog();
  };

  useEffect(() => {
    const data = {
      name: null,
    };
    getAllMembersForNotification(data);
  }, [getAllMembersForNotification]);

  const openDialogForMultipleUsers = (data) => {
    console.log('[NOTIF_DEBUG allMembersForNotification]', allMembersForNotification);
    // if (data.title) {
    setNotificationData(data);
    // }
    setIsDialogForMultipleUsersOpen(true);
  };

  const closeDialogForMultipleUsers = () => {
    setIsDialogForMultipleUsersOpen(false);
    setNotificationData(undefined);
  };

  const submitNotificationForMultipleUsers = (values) => {
    console.log('[NOTIFICATION_IMAGE values]', values);
    let userIds = null;
    if (values.userIds && values.userIds.split(',').length > 0) {
      userIds = values.userIds.split(',');
    }
    const dataToSend = {
      // externalIds: userIds,
      // externalIds: ["5f38caa5c89bdc28ba532ffe"],
      title: values.title,
      body: values.body,
      notificationImage: values.notificationImage,
      notificationType: 'ENTITY',
      notificationPayload: { _id: values._id },
    };
    if (sendingMode === 2) {
      dataToSend.externalIds = userIds;
    }
    console.log('[NOTIFICATION_IMAGE dataToSend]', dataToSend);
    createCustomNotification(dataToSend);
    closeDialogForMultipleUsers();
  };

  return (
    <div className="entities memberships members">
      <div className="page-title">
        <h1>Entities</h1>
      </div>
      <Paper className="paper">
        <Table stickyHeader className="table">
          <TableHead className="table-head">
            <TableRow>
              <TableCell className="th" />
              <TableCell className="th">
                <span>Profile Image</span>
              </TableCell>
              <TableCell className="th">
                <span>Banner Image</span>
              </TableCell>
              <TableCell className="th">
                <span>First Name</span>
              </TableCell>
              <TableCell className="th">
                <span>Last Name</span>
              </TableCell>
              <TableCell className="th">
                <span>IsFeatured</span>
              </TableCell>
              <TableCell className="th">
                <span>isPremium</span>
              </TableCell>
              <TableCell className="th">
                <span>Type</span>
              </TableCell>
              <TableCell className="th">
                <span>Description</span>
              </TableCell>
              <TableCell className="th">
                <span>Actions</span>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody className="table-body">
            {allEntities
              .slice((activePage - 1) * itemsPerPage, activePage * itemsPerPage)
              .map((row, index) => (
                <TableRow key={row.name}>
                  <TableCell className="cell" style={{ textAlign: 'center' }}>
                    <span># {index + 1}</span>
                  </TableCell>
                  <TableCell className="cell">
                    <img
                      alt="animation"
                      src={`${ASSETS_BASE_URL}/${row.profileImage}`}
                      className="thumbnail"
                    />
                  </TableCell>
                  <TableCell className="cell">
                    <img
                      alt="animation"
                      src={`${ASSETS_BASE_URL}/${row.bannerImage}`}
                      className="thumbnail"
                    />
                  </TableCell>
                  <TableCell className="cell">
                    <span>{row.firstName}</span>
                  </TableCell>
                  <TableCell className="cell">
                    <span>{row.lastName}</span>
                  </TableCell>
                  <TableCell className="cell">
                    <span>{row.isFeatured ? 'Yes' : 'No'}</span>
                  </TableCell>
                  <TableCell className="cell">
                    <span>{row.isPremium ? 'Yes' : 'No'}</span>
                  </TableCell>
                  <TableCell className="cell">
                    <span>{capitalizeFirstLetter(row.type)}</span>
                  </TableCell>
                  <TableCell className="cell">
                    <span>{row.description}</span>
                  </TableCell>
                  <TableCell className="cell">
                    <div className="head-cell cell-actions">
                      <div className="action-icons">
                        <div
                          className="action-icon"
                          role="presentation"
                          onClick={() => openDialog(row)}
                        >
                          <img src={icoEdit} alt="" className="edit-ico" />
                        </div>
                        <div
                          className="action-icon"
                          role="presentation"
                          onClick={() => openDeleteDialog(row)}
                        >
                          <img src={icoTrash} alt="" className="edit-ico" />
                        </div>
                        <div
                          className="action-icon"
                          role="presentation"
                          onClick={() => openDialogForMultipleUsers(row)}
                        >
                          <IoIosNotifications size={23} color="#00264e" />
                        </div>
                      </div>
                    </div>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
        <div className="add-ico" role="presentation" onClick={openDialog}>
          <img src={icoAdd} alt="" />
        </div>
      </Paper>

      {pagesCount !== 1 ? (
        <div className="pagination-wrapper">
          <Pagination count={pagesCount} page={activePage} onChange={handlePageChange} />
        </div>
      ) : null}

      <Dialog open={isDialogOpen} onClose={closeDialog} className="entitys-dialog">
        <DialogTitle>{entityData ? 'Update Entity' : 'Add Entity'}</DialogTitle>
        <DialogContent>
          <EntityForm initialValues={entityData} closeDialog={closeDialog} onSubmit={submit} />
        </DialogContent>
      </Dialog>

      <Dialog open={isDeleteDialogOpen} onClose={closeDeleteDialog} className="entitys-dialog">
        <DialogTitle>Delete Entity</DialogTitle>
        <DialogContent>
          <p>Are you sure you want to delete this Entity?</p>
          <div className="text-center customForm">
            <button
              type="button"
              onClick={closeDeleteDialog}
              className="submit-button cancel-button"
            >
              No
            </button>
            <button type="button" className="submit-button" onClick={confirmDelete}>
              Yes
            </button>
          </div>
        </DialogContent>
      </Dialog>
      <Dialog
        open={isDialogForMultipleUsersOpen}
        onClose={closeDialogForMultipleUsers}
        className="notifications-dialog"
      >
        <DialogTitle>Send Notification To Multiple Users</DialogTitle>
        <DialogContent>
          <CustomNotificationForm
            initialValues={notificationData}
            closeDialog={closeDialogForMultipleUsers}
            onSubmit={submitNotificationForMultipleUsers}
            allMembersForNotification={allMembersForNotification}
            getAllMembersForNotification={getAllMembersForNotification}
            notificationType="ENTITY"
            sendingMode={sendingMode}
            setSendingMode={setSendingMode}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
}

Entities.propTypes = {
  addEntity: PropTypes.func,
  updateEntity: PropTypes.func,
  deleteEntity: PropTypes.func,
  getAllEntities: PropTypes.func,
  allEntities: PropTypes.arrayOf(PropTypes.shape({})),
  getAllMembersForNotification: PropTypes.func,
  allMembersForNotification: PropTypes.arrayOf(PropTypes.shape({})),
  createCustomNotification: PropTypes.func,
};

Entities.defaultProps = {
  addEntity: undefined,
  updateEntity: undefined,
  deleteEntity: undefined,
  getAllEntities: undefined,
  allEntities: undefined,
  getAllMembersForNotification: undefined,
  allMembersForNotification: undefined,
  createCustomNotification: undefined,
};

const mapStateToProps = (state) => ({
  loading: state.loading,
  allEntities: state.allEntities,
  allMembersForNotification: state.allMembersForNotification,
});

const actionsStateToProps = {
  addEntity: (data) => ({ type: ENTITY_ADD_REQUEST, data }),
  updateEntity: (data) => ({ type: ENTITY_UPDATE_REQUEST, data }),
  deleteEntity: (data) => ({ type: ENTITY_DELETE_REQUEST, data }),
  getAllEntities: () => ({ type: GET_ALL_ENTITYS_REQUEST }),
  getAllMembersForNotification: (data) => ({
    type: GET_ALL_MEMBERS_FOR_NOTIFICATION_REQUEST,
    data,
  }),
  createCustomNotification: (data) => ({ type: CREATE_CUSTOM_NOTIFICATION, data }),
};

export default connect(mapStateToProps, actionsStateToProps)(Entities);
