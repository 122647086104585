import React from 'react';
import PropTypes from 'prop-types';

import { Field, reduxForm, Form } from 'redux-form';
import TextField from '../common/TextField';
import FileField from '../common/FileField';
import SelectField from '../common/SelectField';

const required = (value) => (value && value !== '' ? undefined : 'Required');

function EntityForm(props) {
  const { handleSubmit, pristine, submitting, closeDialog, initialValues } = props;

  // const [type, setType] = useState(null);

  // Get Initial Data

  return (
    <Form onSubmit={handleSubmit} className="customForm addMembership coupons">
      <div>
        <Field
          name="firstName"
          component={TextField}
          label="First Name"
          placeholder="Enter First Name"
          validate={required}
        />
      </div>
      <div>
        <Field
          name="lastName"
          component={TextField}
          label="Last Name"
          placeholder="Enter Last Name"
          validate={required}
        />
      </div>
      <div>
        <Field
          name="description"
          component={TextField}
          label="Description"
          placeholder="Enter Entity Description"
          validate={required}
        />
      </div>
      <div>
        <Field name="isFeatured" component={SelectField} label="Is Featured">
          <option value="">Select</option>
          <option value>Yes</option>
          <option value={false}>No</option>
        </Field>
      </div>
      <div>
        <Field name="isPremium" component={SelectField} label="Is Premium?">
          <option value="">Select</option>
          <option value>Yes</option>
          <option value={false}>No</option>
        </Field>
      </div>
      <div>
        <Field
          name="type"
          component={SelectField}
          label="Entity Type"
          // onChange={e => setType(e.target.value)}
          placeholder=""
        >
          <option value="">Select Entity</option>
          <option value="artist">Artist</option>
          <option value="instructor">Instructor</option>
        </Field>
      </div>
      <div>
        <Field
          name="profile"
          label="Select Profile Image"
          placeholder="Select Profile Image"
          component={FileField}
          validate={undefined}
        />
      </div>
      <div>
        <Field
          name="banner"
          label="Select Banner Image"
          placeholder="Select Banner Image"
          component={FileField}
          validate={undefined}
        />
      </div>
      <div className="text-right">
        <button type="button" onClick={closeDialog} className="submit-button cancel-button">
          Cancel
        </button>
        <button type="submit" className="submit-button" disabled={pristine || submitting}>
          {initialValues ? 'Update' : 'Add'}
        </button>
      </div>
    </Form>
  );
}

EntityForm.propTypes = {
  initialValues: PropTypes.shape({}),
  closeDialog: PropTypes.func,
  handleSubmit: PropTypes.func,
  pristine: PropTypes.bool,
  submitting: PropTypes.bool,
};

EntityForm.defaultProps = {
  initialValues: undefined,
  closeDialog: undefined,
  handleSubmit: undefined,
  pristine: undefined,
  submitting: undefined,
};

export default reduxForm({
  form: 'addMembership',
})(EntityForm);
