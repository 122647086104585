import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Pagination from '@material-ui/lab/Pagination';
import axios from 'axios';
import Item from './Item';
import Sorting from './Sorting';
import {
  GET_ALL_CONTENT_REQUEST,
  GET_ALL_SERIES_REQUEST,
  GET_ALL_PROGRAMS_REQUEST,
  GET_ALL_MEMBERSHIPS_REQUEST,
  GET_ALL_GROUPS_REQUEST,
  GET_ALL_MEDITATIONS_REQUEST,
  SINGLE_UPDATE_REQUEST,
  SINGLE_DELETE_REQUEST,
  SERIES_UPDATE_REQUEST,
  SERIES_DELETE_REQUEST,
  GET_ALL_AUDIOS_REQUEST,
} from '../../redux/actions/actionTypes';

let tokenToCancelReq = null;
const { CancelToken } = axios;

function ItemsList(props) {
  const {
    listType,
    allMemberships,
    allGroups,
    getAllGroups,
    getAllMemberships,
    allContent,
    allAudios,
    getAllContent,
    getAllAudios,
    allSeries,
    allPrograms,
    allMeditations,
    getAllMeditations,
    getAllSeries,
    getAllPrograms,
    updateSingle,
    deleteSingle,
    updateSeries,
    deleteSeries,
  } = props;

  const [curListType] = useState(-1);
  const [isActive, setIsActive] = useState(-1);
  const [mediaType, setMediaType] = useState(listType);
  const [rawData, setRawData] = useState({ data: [] });
  const [searchText, setSearchText] = useState('');
  const [itemsList, setItemsList] = useState({ data: [] });
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);

  // Pagination
  const [activePage, setActivePage] = useState(1);
  const [pagesCount, setPagesCount] = useState(1);
  const [itemsPerPage] = useState(10);

  useEffect(() => {
    if (itemsList) {
      let curList;
      if (mediaType === 'series') {
        curList = itemsList.data.filter(
          (item) => item.type === `series${listType === 'videos' ? 'Video' : 'Audio'}`
        );
      } else if (mediaType === 'program') {
        curList = itemsList.data.filter(
          (item) => item.type === `program${listType === 'videos' ? 'Video' : 'Audio'}`
        );
      } else {
        curList = itemsList.data.filter(
          (item) => item.type === `single${listType === 'videos' ? 'Video' : 'Audio'}`
        );
      }
      if (listType !== 'audios') {
        setPagesCount(Math.ceil(curList.length / itemsPerPage));
      }
    }
  }, [itemsList, mediaType, itemsPerPage, listType]);
  useEffect(() => {
    if (activePage > pagesCount) {
      setActivePage(1);
    }
  }, [pagesCount, activePage]);

  const handlePageChange = (event, value) => {
    setActivePage(value);
    if (listType === 'audios') {
      const data = {
        limit: itemsPerPage,
        page: value,
      };
      getAllAudios(data);
    }
  };

  // Get Initial Data
  useEffect(() => {
    if (listType === 'videos') {
      getAllContent();
    } else {
      const data = {
        limit: itemsPerPage,
        page: activePage,
      };
      getAllAudios(data);
    }
  }, [getAllContent, getAllAudios]);

  // Get Initial Data
  useEffect(() => {
    if (allGroups.length === 0) {
      getAllGroups();
    }
  }, [getAllGroups, allGroups.length]);

  useEffect(() => {
    getAllSeries({ type: `series${listType === 'videos' ? 'Video' : 'Audio'}` });
  }, [getAllSeries, listType]);

  useEffect(() => {
    getAllPrograms({ type: `program${listType === 'videos' ? 'Video' : 'Audio'}` });
  }, [getAllPrograms, listType]);

  useEffect(() => {
    if (allMemberships.length === 0) {
      getAllMemberships();
    }
  }, [getAllMemberships, allMemberships.length]);

  // Get Meditations List
  useEffect(() => {
    getAllMeditations();
  }, [getAllMeditations]);

  // Update raw Data if there is changes at page
  useEffect(() => {
    if (mediaType === 'series') {
      setRawData({ data: allSeries });
      setIsActive(-1);
    }
    if (mediaType === 'program') {
      setRawData({ data: allPrograms });
      setIsActive(-1);
    } else if (listType === 'videos') {
      setRawData({ data: allContent });
      setIsActive(-1);
      // eslint-disable-next-line
    } else if (
      allAudios &&
      allAudios.list &&
      Array.isArray(allAudios.list) &&
      allAudios.list.length > 0
    ) {
      setRawData({ data: allAudios.list });
      setPagesCount(allAudios.totalPages);
      setIsActive(-1);
    }
  }, [allContent, allAudios, allSeries, allPrograms, mediaType]);

  useEffect(() => {
    if (listType !== curListType) {
      // setCurListType(listType);
      setMediaType(listType);
    }
  }, [listType, curListType]);

  const changeTabs = (tab) => {
    setMediaType(tab);
  };

  const updateArray = (newArray) => {
    setItemsList({ data: newArray });
  };

  // Open/close item
  const setActive = (id) => {
    if (isActive === id) {
      setIsActive(-1);
    } else {
      setIsActive(id);
    }
  };

  // Update item
  const submit = (values) => {
    if (values.type.indexOf('single') !== -1) {
      updateSingle(values);
    } else {
      updateSeries(values);
    }
  };

  // Delete item
  const openDeleteDialog = (id, type) => {
    setIsDeleteDialogOpen({ id, type });
  };

  const closeDeleteDialog = () => {
    setIsDeleteDialogOpen(false);
  };

  const confirmDelete = () => {
    if (isDeleteDialogOpen.type.indexOf('single') !== -1) {
      deleteSingle(isDeleteDialogOpen.id);
    } else {
      deleteSeries({ id: isDeleteDialogOpen.id, type: isDeleteDialogOpen.type });
    }
    closeDeleteDialog();
  };

  const searchByText = (e) => {
    setSearchText(e.target.value);
  };

  useEffect(() => {
    if (listType === 'audios') {
      if (tokenToCancelReq) {
        tokenToCancelReq.cancel();
      }
      tokenToCancelReq = CancelToken.source();
      const data = {
        limit: itemsPerPage,
        page: 1,
        query: searchText,
        cancelToken: tokenToCancelReq.token,
      };
      getAllAudios(data);
    }
  }, [searchText]);

  return (
    <>
      <div className="videos">
        <div className="choose-type">
          <div
            className={mediaType === listType ? 'type-item active' : 'type-item'}
            role="presentation"
            onClick={() => changeTabs(listType)}
          >
            {listType}
          </div>
          {/* <div
            className={mediaType === 'series' ? 'type-item active' : 'type-item'}
            role="presentation"
            onClick={() => changeTabs('series')}
          >
            Series
          </div>
          <div
            className={mediaType === 'program' ? 'type-item active' : 'type-item'}
            role="presentation"
            onClick={() => changeTabs('program')}
          >
            Program
          </div> */}
        </div>

        <Paper className="sorting-paper">
          <Sorting
            title={listType}
            data={rawData.data}
            updateData={updateArray}
            formName={`${listType}${mediaType.toString()}`}
            searchByText={searchByText}
          />
        </Paper>

        <Paper className="paper">
          <div className="videos-head">
            <div className="head-cell head-name">Name</div>
            <div className="head-cell head-date">Created Date</div>
          </div>
          {/* eslint-disable-next-line */}
          {mediaType === listType
            ? listType === 'videos'
              ? itemsList.data
                  .filter(
                    (item) => item.type === `single${listType === 'videos' ? 'Video' : 'Audio'}`
                  )
                  .slice((activePage - 1) * itemsPerPage, activePage * itemsPerPage)
                  .map((item) => (
                    <Item
                      initialValues={item}
                      // eslint-disable-next-line
                      key={item['_id']}
                      // eslint-disable-next-line
                      isActive={isActive === item['_id']}
                      deleteItem={openDeleteDialog}
                      setActive={setActive}
                      allMemberships={allMemberships}
                      allGroups={allGroups}
                      allSeries={allSeries}
                      allPrograms={allPrograms}
                      allMeditations={allMeditations}
                      // eslint-disable-next-line
                      formName={item['_id']}
                      onSubmit={submit}
                    />
                  ))
              : itemsList.data
                  .filter(
                    (item) => item.type === `single${listType === 'videos' ? 'Video' : 'Audio'}`
                  )
                  // .slice((activePage - 1) * itemsPerPage, activePage * itemsPerPage)
                  .map((item) => (
                    <Item
                      initialValues={item}
                      // eslint-disable-next-line
                      key={item['_id']}
                      // eslint-disable-next-line
                      isActive={isActive === item['_id']}
                      deleteItem={openDeleteDialog}
                      setActive={setActive}
                      allMemberships={allMemberships}
                      allGroups={allGroups}
                      allSeries={allSeries}
                      allPrograms={allPrograms}
                      allMeditations={allMeditations}
                      // eslint-disable-next-line
                      formName={item['_id']}
                      onSubmit={submit}
                    />
                  ))
            : null}
          {mediaType === 'series'
            ? itemsList.data
                .filter(
                  (item) => item.type === `series${listType === 'videos' ? 'Video' : 'Audio'}`
                )
                .slice((activePage - 1) * itemsPerPage, activePage * itemsPerPage)
                .map((item) => (
                  <Item
                    initialValues={item}
                    // eslint-disable-next-line
                    key={item['_id']}
                    // eslint-disable-next-line
                    isActive={isActive === item['_id']}
                    deleteItem={openDeleteDialog}
                    setActive={setActive}
                    allMemberships={allMemberships}
                    allSeries={allSeries}
                    allPrograms={allPrograms}
                    allMeditations={allMeditations}
                    // eslint-disable-next-line
                    formName={item['_id']}
                    onSubmit={submit}
                  />
                ))
            : itemsList.data
                .filter(
                  (item) => item.type === `program${listType === 'videos' ? 'Video' : 'Audio'}`
                )
                .slice((activePage - 1) * itemsPerPage, activePage * itemsPerPage)
                .map((item) => (
                  <Item
                    initialValues={item}
                    // eslint-disable-next-line
                    key={item['_id']}
                    // eslint-disable-next-line
                    isActive={isActive === item['_id']}
                    deleteItem={openDeleteDialog}
                    setActive={setActive}
                    allMemberships={allMemberships}
                    allSeries={allSeries}
                    allPrograms={allPrograms}
                    allMeditations={allMeditations}
                    // eslint-disable-next-line
                    formName={item['_id']}
                    onSubmit={submit}
                  />
                ))}
        </Paper>

        {pagesCount !== 1 ? (
          <div className="pagination-wrapper">
            <Pagination count={pagesCount} page={activePage} onChange={handlePageChange} />
          </div>
        ) : null}
      </div>

      <Dialog
        open={!!isDeleteDialogOpen}
        onClose={closeDeleteDialog}
        className="memberships-dialog"
      >
        <DialogTitle>Delete</DialogTitle>
        <DialogContent>
          <p>Are you sure you want to delete it?</p>
          <div className="text-center customForm">
            <button
              type="button"
              onClick={closeDeleteDialog}
              className="submit-button cancel-button"
            >
              No
            </button>
            <button type="button" className="submit-button" onClick={confirmDelete}>
              Yes
            </button>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
}

ItemsList.propTypes = {
  listType: PropTypes.string,
  getAllMemberships: PropTypes.func,
  getAllGroups: PropTypes.func,
  allMemberships: PropTypes.arrayOf(PropTypes.shape({})),
  allGroups: PropTypes.arrayOf(PropTypes.shape({})),
  getAllContent: PropTypes.func,
  allContent: PropTypes.arrayOf(PropTypes.shape({})),
  getAllAudios: PropTypes.func,
  allAudios: PropTypes.arrayOf(PropTypes.shape({})),
  getAllSeries: PropTypes.func,
  getAllPrograms: PropTypes.func,
  allSeries: PropTypes.arrayOf(PropTypes.shape({})),
  allPrograms: PropTypes.arrayOf(PropTypes.shape({})),
  updateSingle: PropTypes.func,
  getAllMeditations: PropTypes.func,
  allMeditations: PropTypes.arrayOf(PropTypes.shape({})),
  deleteSingle: PropTypes.func,
  updateSeries: PropTypes.func,
  deleteSeries: PropTypes.func,
};

ItemsList.defaultProps = {
  listType: undefined,
  getAllMemberships: undefined,
  getAllGroups: undefined,
  allMemberships: undefined,
  allGroups: undefined,
  getAllContent: undefined,
  allContent: undefined,
  getAllAudios: undefined,
  allAudios: undefined,
  getAllSeries: undefined,
  getAllPrograms: undefined,
  allSeries: undefined,
  allPrograms: undefined,
  updateSingle: undefined,
  deleteSingle: undefined,
  updateSeries: undefined,
  deleteSeries: undefined,
  getAllMeditations: undefined,
  allMeditations: undefined,
};

const mapStateToProps = (state) => ({
  allContent: state.allContent,
  allAudios: state.allAudios,
  allSeries: state.allSeries,
  allPrograms: state.allPrograms,
  allMeditations: state.allMeditations,
  allMemberships: state.allMemberships,
  allGroups: state.allGroups,
});

const actionsStateToProps = {
  getAllMemberships: () => ({ type: GET_ALL_MEMBERSHIPS_REQUEST }),
  getAllContent: () => ({ type: GET_ALL_CONTENT_REQUEST }),
  getAllAudios: (data) => ({ type: GET_ALL_AUDIOS_REQUEST, data }),
  getAllSeries: (serType) => ({ type: GET_ALL_SERIES_REQUEST, serType }),
  getAllPrograms: (serType) => ({ type: GET_ALL_PROGRAMS_REQUEST, serType }),
  updateSingle: (data) => ({ type: SINGLE_UPDATE_REQUEST, data }),
  deleteSingle: (id) => ({ type: SINGLE_DELETE_REQUEST, id }),
  updateSeries: (data) => ({ type: SERIES_UPDATE_REQUEST, data }),
  deleteSeries: (data) => ({ type: SERIES_DELETE_REQUEST, data }),
  getAllMeditations: () => ({ type: GET_ALL_MEDITATIONS_REQUEST }),
  getAllGroups: () => ({ type: GET_ALL_GROUPS_REQUEST }),
};

export default connect(mapStateToProps, actionsStateToProps)(ItemsList);
