import React from 'react';
import PropTypes from 'prop-types';

import { Field, reduxForm, Form } from 'redux-form';
import MultiselectField from '../common/MultiselectField';
import TextField from '../common/TextField';
import FileField from '../common/FileField';
import SelectField from '../common/SelectField';

const required = (value) => (value && value !== '' ? undefined : 'Required');
// const fileSelect = value => (value ? undefined : 'You forgot to choose image');

function NotificationForm(props) {
  const {
    handleSubmit,
    pristine,
    submitting,
    closeDialog,
    initialValues,
    allMembersForNotification,
  } = props;

  // Get Initial Data

  return (
    <Form onSubmit={handleSubmit} className="customForm addMembership coupons">
      <div>
        <Field
          name="userIds"
          component={MultiselectField}
          label="Select Users"
          placeholder="Select Users"
          dataArray={allMembersForNotification}
          validate={required}
        >
          {allMembersForNotification.map((item) => item)}
        </Field>
      </div>
      <div>
        <Field
          name="title"
          component={TextField}
          label="Notification Title"
          placeholder="Enter Notification Title"
          validate={required}
        />
      </div>
      <div>
        <Field
          name="body"
          component={TextField}
          label="Notification Body"
          placeholder="Enter Notification Body"
          validate={required}
        />
      </div>
      <div>
        <Field
          name="notificationType"
          component={SelectField}
          label="Select Category"
          validate={required}
          placeholder=""
        >
          <option value="GENERAL">General</option>
          <option value="BADGE">Badge</option>
          <option value="UPGRADE">Upgrade Membership</option>
        </Field>
      </div>
      <div>
        <Field
          name="notificationImage"
          label="Select Notification Image(Optional)"
          placeholder="Select Notification Image(Optional)"
          component={FileField}
          validate={undefined}
        />
      </div>

      <div className="text-right">
        <button type="button" onClick={closeDialog} className="submit-button cancel-button">
          Cancel
        </button>
        <button type="submit" className="submit-button" disabled={pristine || submitting}>
          {initialValues ? 'Update' : 'Add'}
        </button>
      </div>
    </Form>
  );
}

NotificationForm.propTypes = {
  initialValues: PropTypes.shape({}),
  closeDialog: PropTypes.func,
  handleSubmit: PropTypes.func,
  pristine: PropTypes.bool,
  submitting: PropTypes.bool,
  allMembersForNotification: PropTypes.arrayOf(PropTypes.shape({})),
};

NotificationForm.defaultProps = {
  initialValues: undefined,
  closeDialog: undefined,
  handleSubmit: undefined,
  pristine: undefined,
  submitting: undefined,
  allMembersForNotification: undefined,
};

export default reduxForm({
  form: 'addMembership',
})(NotificationForm);
