import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import SingleUploadForm from './SingleUploadForm';
import MultipleUploadForm from './MultipleUploadForm';
import {
  UPLOAD_SINGLE_REQUEST,
  UPLOAD_SERIES_REQUEST,
  GET_ALL_MEMBERSHIPS_REQUEST,
  GET_ALL_CONTENT_REQUEST,
  GET_ALL_SERIES_REQUEST,
  GET_ALL_GROUPS_REQUEST,
  GET_ALL_MEDITATIONS_REQUEST,
} from '../../redux/actions/actionTypes';

function Step3(props) {
  const {
    uploadFormat,
    uploadType,
    uploadSingle,
    uploadSeries,
    allMemberships,
    getAllMemberships,
    getAllMeditations,
    allContent,
    getAllContent,
    allGroups,
    getAllSeries,
    getAllGroups,
    uploadedSeries,
    uploadedSingle,
  } = props;

  // Get Initial Data
  useEffect(() => {
    getAllSeries({ type: `series${uploadFormat}` });
    getAllGroups();
  }, [getAllSeries, getAllGroups, uploadFormat]);

  useEffect(() => {
    if (allMemberships.length === 0) {
      getAllMemberships();
    }
  }, [getAllMemberships, allMemberships.length]);

  // Get Meditations List
  useEffect(() => {
    getAllMeditations();
  }, [getAllMeditations]);

  useEffect(() => {
    if (allContent.length === 0) {
      getAllContent();
    }
  }, [getAllContent, allContent.length]);

  // Upload Single/Series
  const submit = (values) => {
    if (uploadType === 'single') {
      uploadSingle(values);
    } else if (uploadType === 'series') {
      uploadSeries(values);
    } else if (uploadType === 'program') {
      uploadSeries(values, true);
    }
  };

  // Config Initial values for Forms
  const initialValues = {};
  initialValues.type = `${uploadType}${uploadFormat}`;
  if (Object.keys(uploadedSeries).length !== 0) {
    // eslint-disable-next-line
    initialValues.seriesId = uploadedSeries['_id'];
  }

  const namesArray = allContent.map((item) => item.name);

  return (
    <div className="upload-step3">
      {uploadType === 'single' ? (
        <SingleUploadForm
          onSubmit={submit}
          initialValues={initialValues}
          uploadFormat={uploadFormat}
          allMemberships={allMemberships}
          allGroups={allGroups}
          namesArray={namesArray}
          formName="SingleUploadForm"
        />
      ) : (
        <MultipleUploadForm
          onSubmit={submit}
          uploadType={uploadType}
          initialValues={initialValues}
          uploadFormat={uploadFormat}
          allMemberships={allMemberships}
          namesArray={namesArray}
          uploadSingle={uploadSingle}
          uploadedSingle={uploadedSingle}
          formName="MultipleUploadForm"
        />
      )}
    </div>
  );
}

Step3.propTypes = {
  uploadType: PropTypes.string,
  uploadFormat: PropTypes.string,
  uploadSingle: PropTypes.func,
  uploadSeries: PropTypes.func,
  getAllMemberships: PropTypes.func,
  getAllMeditations: PropTypes.func,
  getAllGroups: PropTypes.func,
  allMemberships: PropTypes.arrayOf(PropTypes.shape({})),
  uploadedSeries: PropTypes.shape({}),
  uploadedSingle: PropTypes.shape({}),
  getAllContent: PropTypes.func,
  allContent: PropTypes.arrayOf(PropTypes.shape({})),
  getAllSeries: PropTypes.func,
  allGroups: PropTypes.arrayOf(PropTypes.shape({})),
};

Step3.defaultProps = {
  uploadType: undefined,
  uploadFormat: undefined,
  uploadSingle: undefined,
  uploadSeries: undefined,
  getAllMemberships: undefined,
  getAllMeditations: undefined,
  allMemberships: undefined,
  uploadedSeries: undefined,
  uploadedSingle: undefined,
  getAllContent: undefined,
  getAllGroups: undefined,
  allContent: undefined,
  getAllSeries: undefined,
  allGroups: undefined,
};

const mapStateToProps = (state) => ({
  allMemberships: state.allMemberships,
  uploadedSeries: state.uploadedSeries,
  uploadedSingle: state.uploadedSingle,
  allContent: state.allContent,
  allGroups: state.allGroups,
});

const actionsStateToProps = {
  getAllMemberships: () => ({ type: GET_ALL_MEMBERSHIPS_REQUEST }),
  getAllMeditations: () => ({ type: GET_ALL_MEDITATIONS_REQUEST }),
  getAllContent: () => ({ type: GET_ALL_CONTENT_REQUEST }),
  getAllSeries: (serType) => ({ type: GET_ALL_SERIES_REQUEST, serType }),
  getAllGroups: () => ({ type: GET_ALL_GROUPS_REQUEST }),
  uploadSingle: (data) => ({ type: UPLOAD_SINGLE_REQUEST, data }),
  uploadSeries: (data, isProgram) => ({ type: UPLOAD_SERIES_REQUEST, data, isProgram }),
};

export default connect(mapStateToProps, actionsStateToProps)(Step3);
