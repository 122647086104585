import axios from 'axios';
import cookie from 'react-cookies';

export default function axiosHandler(obj) {
  if (cookie.load('token')) {
    axios.interceptors.request.use((config) => {
      const token = cookie.load('token');
      /* eslint-disable-next-line */
      config.headers.Authorization = token;

      return config;
    });
  }
  return axios(obj);
}
