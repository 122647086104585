export const isExist = (valuesAray, value) => valuesAray.indexOf(value) === -1;

export const getDate = (date) => {
  const showDate = new Date(date);
  return showDate.toLocaleDateString('en-US');
};

export const getMembershipData = (membershipsArray, membershipId) => {
  // eslint-disable-next-line
  const membershipItem = membershipsArray.filter((item) => item['_id'] === membershipId)[0];
  if (membershipItem) {
    return { name: membershipItem.name, id: membershipId };
  }
  return { name: '', id: membershipId };
};

export const getGroupData = (groupsArray, groupId) => {
  // eslint-disable-next-line
  const groupItem = groupsArray.filter((item) => item['_id'] === groupId)[0];
  if (groupItem) {
    return { name: groupItem.name, id: groupId };
  }
  return { name: '', id: groupId };
};

export const capitalizeFirstLetter = (string) => string.charAt(0).toUpperCase() + string.slice(1);

export const staticResourceAwsBaseUrl = 'https://public-prod.retreatsounds.com';

export const toHHMMSS = (secs) => {
  const secNum = parseInt(secs, 10);
  const hours = Math.floor(secNum / 3600);
  const minutes = Math.floor(secNum / 60) % 60;
  const seconds = secNum % 60;

  return [hours, minutes, seconds]
    .map((v) => (v < 10 ? `0${v}` : v))
    .filter((v, i) => v !== '00' || i > 0)
    .join(':');
};
