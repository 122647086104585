import { GET_ALL_MEMBERS_FOR_NOTIFICATION_SUCCESS } from '../actions/actionTypes';

export default function allMembersForNotification(state = [], action) {
  switch (action.type) {
    case GET_ALL_MEMBERS_FOR_NOTIFICATION_SUCCESS:
      return action.payload;
    default:
      return state;
  }
}
