export const LOADING = 'LOADING';
export const RELOAD_REQUEST = 'RELOAD_REQUEST';
export const UPLOAD_PROGRESS = 'UPLOAD_PROGRESS';

export const MESSAGE_ERROR = 'MESSAGE_ERROR';
export const MESSAGE_SUCCESS = 'MESSAGE_SUCCESS';

export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';

export const GET_ALL_PARENTS_REQUEST = 'GET_ALL_PARENTS_REQUEST';
export const GET_ALL_PARENTS_SUCCESS = 'GET_ALL_PARENTS_SUCCESS';

export const GET_ALL_MEMBERSHIPS_REQUEST = 'GET_ALL_MEMBERSHIPS_REQUEST';
export const GET_ALL_MEMBERSHIPS_SUCCESS = 'GET_ALL_MEMBERSHIPS_SUCCESS';
export const MEMBERSHIP_ADD_REQUEST = 'MEMBERSHIP_ADD_REQUEST';
export const MEMBERSHIP_UPDATE_REQUEST = 'MEMBERSHIP_UPDATE_REQUEST';
export const MEMBERSHIP_DELETE_REQUEST = 'MEMBERSHIP_DELETE_REQUEST';

export const GET_ALL_COUPONS_REQUEST = 'GET_ALL_COUPONS_REQUEST';
export const GET_ALL_COUPONS_SUCCESS = 'GET_ALL_COUPONS_SUCCESS';
export const COUPON_ADD_REQUEST = 'COUPON_ADD_REQUEST';
export const COUPON_UPDATE_REQUEST = 'COUPON_UPDATE_REQUEST';
export const COUPON_DELETE_REQUEST = 'COUPON_DELETE_REQUEST';

export const GET_ALL_NOTIFICATIONS_REQUEST = 'GET_ALL_NOTIFICATIONS_REQUEST';
export const GET_ALL_NOTIFICATIONS_SUCCESS = 'GET_ALL_NOTIFICATIONS_SUCCESS';
export const NOTIFICATION_ADD_REQUEST = 'NOTIFICATION_ADD_REQUEST';
export const NOTIFICATION_UPDATE_REQUEST = 'NOTIFICATION_UPDATE_REQUEST';
export const NOTIFICATION_DELETE_REQUEST = 'NOTIFICATION_DELETE_REQUEST';
export const GROUP_NOTIFICATION_ADD_REQUEST = 'GROUP_NOTIFICATION_ADD_REQUEST';

export const GET_ALL_ENTITYS_REQUEST = 'GET_ALL_ENTITYS_REQUEST';
export const GET_ALL_ENTITYS_SUCCESS = 'GET_ALL_ENTITYS_SUCCESS';
export const ENTITY_ADD_REQUEST = 'ENTITY_ADD_REQUEST';
export const ENTITY_UPDATE_REQUEST = 'ENTITY_UPDATE_REQUEST';
export const ENTITY_DELETE_REQUEST = 'ENTITY_DELETE_REQUEST';

export const GET_ALL_GROUPS_REQUEST = 'GET_ALL_GROUPS_REQUEST';
export const GET_ALL_GROUPS_SUCCESS = 'GET_ALL_GROUPS_SUCCESS';
export const GROUP_ADD_REQUEST = 'GROUP_ADD_REQUEST';
export const GROUP_UPDATE_REQUEST = 'GROUP_UPDATE_REQUEST';
export const GROUP_DELETE_REQUEST = 'GROUP_DELETE_REQUEST';

export const GET_ALL_MEDITATIONS_REQUEST = 'GET_ALL_MEDITATIONS_REQUEST';
export const GET_ALL_MEDITATIONS_SUCCESS = 'GET_ALL_MEDITATIONS_SUCCESS';
export const MEDITATION_ADD_REQUEST = 'MEDITATION_ADD_REQUEST';
export const MEDITATION_UPDATE_REQUEST = 'MEDITATION_UPDATE_REQUEST';
export const MEDITATION_DELETE_REQUEST = 'MEDITATION_DELETE_REQUEST';

export const TAG_ADD_REQUEST = 'TAG_ADD_REQUEST';
export const TAG_UPDATE_REQUEST = 'TAG_UPDATE_REQUEST';
export const GET_ALL_TAGS_REQUEST = 'GET_ALL_TAGS_REQUEST';
export const GET_ALL_TAGS_SUCCESS = 'GET_ALL_TAGS_SUCCESS';

export const UPLOAD_SINGLE_REQUEST = 'UPLOAD_SINGLE_REQUEST';
export const UPLOAD_SINGLE_SUCCESS = 'UPLOAD_SINGLE_SUCCESS';
export const UPLOAD_SERIES_REQUEST = 'UPLOAD_SERIES_REQUEST';
export const UPLOAD_SERIES_SUCCESS = 'UPLOAD_SERIES_SUCCESS';

export const GET_ALL_CONTENT_REQUEST = 'GET_ALL_CONTENT_REQUEST';
export const GET_ALL_CONTENT_SUCCESS = 'GET_ALL_CONTENT_SUCCESS';
export const GET_ALL_SERIES_REQUEST = 'GET_ALL_SERIES_REQUEST';
export const GET_ALL_PROGRAMS_REQUEST = 'GET_ALL_PROGRAMS_REQUEST';
export const GET_ALL_SERIES_SUCCESS = 'GET_ALL_SERIES_SUCCESS';
export const GET_ALL_PROGRAMS_SUCCESS = 'GET_ALL_PROGRAMS_SUCCESS';
export const SINGLE_UPDATE_REQUEST = 'SINGLE_UPDATE_REQUEST';
export const SINGLE_DELETE_REQUEST = 'SINGLE_DELETE_REQUEST';
export const SERIES_UPDATE_REQUEST = 'SERIES_UPDATE_REQUEST';
export const SERIES_DELETE_REQUEST = 'SERIES_DELETE_REQUEST';
export const GET_SINGLE_AUDIO_DETAILS_REQUEST = 'GET_SINGLE_AUDIO_DETAILS_REQUEST';
export const GET_SINGLE_AUDIO_DETAILS_SUCCESS = 'GET_SINGLE_AUDIO_DETAILS_SUCCESS';

export const GET_ALL_MEMBERS_REQUEST = 'GET_ALL_MEMBERS_REQUEST';
export const GET_ALL_MEMBERS_SUCCESS = 'GET_ALL_MEMBERS_SUCCESS';
export const MEMBERS_ADD_REQUEST = 'MEMBERS_ADD_REQUEST';
export const MEMBERS_UPDATE_REQUEST = 'MEMBERS_UPDATE_REQUEST';
export const MEMBERS_DELETE_REQUEST = 'MEMBERS_DELETE_REQUEST';

export const NOTIFICATION_ADD_FOR_MEMBER_REQUEST = 'NOTIFICATION_ADD_FOR_MEMBER_REQUEST';
export const CREATE_CUSTOM_NOTIFICATION = 'CREATE_CUSTOM_NOTIFICATION';
export const GET_ALL_MEMBERS_FOR_NOTIFICATION_SUCCESS = 'GET_ALL_MEMBERS_FOR_NOTIFICATION_SUCCESS';
export const GET_ALL_MEMBERS_FOR_NOTIFICATION_REQUEST = 'GET_ALL_MEMBERS_FOR_NOTIFICATION_REQUEST';

export const GET_ALL_AUDIOS_SUCCESS = 'GET_ALL_AUDIOS_SUCCESS';
export const GET_ALL_AUDIOS_REQUEST = 'GET_ALL_AUDIOS_REQUEST';
export const NOTIFICATION_CREATE_REQUEST = 'NOTIFICATION_CREATE_REQUEST';
// export const GROUP_NOTIFICATION_ADD_REQUEST = 'GROUP_NOTIFICATION_ADD_REQUEST';

export const GET_ALL_GROUPS_SEGMENTS_SUCCESS = 'GET_ALL_GROUPS_SEGMENTS_SUCCESS';

export const GET_ALL_GROUPS_SEGMENTS_RESPONSE = 'GET_ALL_GROUPS_SEGMENTS_RESPONSE';
