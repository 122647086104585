import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Pagination from '@material-ui/lab/Pagination';

import TagForm from './TagForm';

import icoEdit from '../../assets/images/icons/ico-edit.svg';

import {
  TAG_ADD_REQUEST,
  TAG_UPDATE_REQUEST,
  GET_ALL_TAGS_REQUEST,
} from '../../redux/actions/actionTypes';

function Tags(props) {
  const { updateTags, getAllTags, allTags } = props;
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [tagsData, setTagsData] = useState();

  // Pagination
  const [activePage, setActivePage] = useState(1);
  const [pagesCount, setPagesCount] = useState(1);
  const [itemsPerPage] = useState(10);

  useEffect(() => {
    if (allTags) {
      setPagesCount(Math.ceil(allTags.length / itemsPerPage));
    }
  }, [allTags, itemsPerPage]);
  useEffect(() => {
    if (activePage > pagesCount) {
      setActivePage(1);
    }
  }, [pagesCount, activePage]);

  const handlePageChange = (event, value) => {
    setActivePage(value);
  };

  useEffect(() => {
    getAllTags();
  }, [getAllTags]);

  // Add/Update Tag
  const openDialog = (data) => {
    // eslint-disable-next-line
    setTagsData(data);
    setIsDialogOpen(true);
  };

  const closeDialog = () => {
    setIsDialogOpen(false);
    setTagsData(undefined);
  };

  const submit = (values) => {
    const updatingData = tagsData;
    // eslint-disable-next-line
    if (tagsData['_id']) {
      // eslint-disable-next-line
      updatingData.id = tagsData['_id'];
    }
    // eslint-disable-next-line no-return-assign
    Object.keys(values).map((item) => (updatingData[item] = values[item]));
    updateTags(updatingData);
    closeDialog();
  };

  return (
    <div className="members tags">
      <div className="page-title">
        <h1>Tags</h1>
      </div>

      <Paper className="paper">
        <div className="members-head">
          <div className="head-cell cell-name">Name</div>
          <div className="head-cell cell-email">Picture</div>
          <div className="head-cell cell-actions">Actions</div>
        </div>

        {allTags.slice((activePage - 1) * itemsPerPage, activePage * itemsPerPage).map((item) => (
          // eslint-disable-next-line
          <div className="item-wrapper" key={item['_id']}>
            <div className="head-cell cell-name">
              <span>{item.name}</span>
            </div>
            <div className="head-cell cell-email">
              {item.fileLocation ? (
                <img src={`https://public-prod.retreatsounds.com/${item.fileLocation}`} className="icon" alt="" />
              ) : (
                <img src={null} className="icon" alt="" />
              )}
            </div>
            <div className="head-cell cell-actions">
              <div className="action-icons">
                <div className="action-icon" role="presentation" onClick={() => openDialog(item)}>
                  <img src={icoEdit} alt="" className="edit-ico" />
                </div>
              </div>
            </div>
          </div>
        ))}
      </Paper>

      {pagesCount !== 1 ? (
        <div className="pagination-wrapper">
          <Pagination count={pagesCount} page={activePage} onChange={handlePageChange} />
        </div>
      ) : null}

      <Dialog open={isDialogOpen} onClose={closeDialog} className="memberships-dialog">
        <DialogTitle>{tagsData ? 'Update Tag' : 'Add Tag'}</DialogTitle>
        <DialogContent>
          <TagForm
            initialValues={tagsData}
            allTags={allTags}
            closeDialog={closeDialog}
            onSubmit={submit}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
}

Tags.propTypes = {
  updateTags: PropTypes.func,
  allTags: PropTypes.arrayOf(PropTypes.shape({})),
  getAllTags: PropTypes.func,
};

Tags.defaultProps = {
  updateTags: undefined,
  allTags: undefined,
  getAllTags: undefined,
};

const mapStateToProps = (state) => ({
  loading: state.loading,
  allTags: state.allTags,
});

const actionsStateToProps = {
  addTag: (data) => ({ type: TAG_ADD_REQUEST, data }),
  updateTags: (data) => ({ type: TAG_UPDATE_REQUEST, data }),
  getAllTags: () => ({ type: GET_ALL_TAGS_REQUEST }),
};

export default connect(mapStateToProps, actionsStateToProps)(Tags);
