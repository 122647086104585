import { call, put } from 'redux-saga/effects';
import axios from './axiosHandler';
import { API_HOST } from '../env';
import sagaLoading from './sagaLoading';
import sagaGetAllTags from './sagaGetAllTags';
import { MESSAGE_ERROR, MESSAGE_SUCCESS } from '../actions/actionTypes';

export default function* sagaAddTag(data) {
  const updateData = new FormData();
  // eslint-disable-next-line
  // updateData.set('id', data['_id']);
  updateData.set('name', data.name);
  if (data.fileLocation) {
    updateData.append('fileLocation', data.fileLocation);
  }
  try {
    yield call(sagaLoading, true);
    yield call(() =>
      axios({
        url: `${API_HOST}content/saveTag`,
        method: 'post',
        data: updateData,
      }).then((response) => response.data)
    );
    yield call(sagaGetAllTags);
    yield put({ type: MESSAGE_SUCCESS, payload: 'Saved successfully!' });
    yield call(sagaLoading, false);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
    yield call(sagaLoading, false);
    yield put({
      type: MESSAGE_ERROR,
      payload: error.message ? error.message : 'There is something wrong, please, try again.',
    });
  }
}
