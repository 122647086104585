import { GET_ALL_GROUPS_SEGMENTS_RESPONSE } from '../actions/actionTypes';

export default function allGroupsSegments(state = [], action) {
  switch (action.type) {
    case GET_ALL_GROUPS_SEGMENTS_RESPONSE:
      return action.payload;
    default:
      return state;
  }
}
